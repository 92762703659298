import React, { Fragment } from 'react'
import LayoutTwo from "../layouts/LayoutTwo";
import Slider from "react-slick";
const AcademyContent = () => {
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
      
        
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 1500,
    speed: 1500,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
      };
  return (
    <Fragment>
      
    <LayoutTwo theme="white">
          <div className='academy position-relative'>
             <img src='images/bg/banner.jpg' alt='banner' draggable="false"></img>
             <div className='acade position-absolute  top-50 left-50'>
                <div className='acde_banner row'>
                    <div className='acdban_lft col-lg-6'>
                  <h1 className='text-light'>Blockchain & Cryptocurrency for a Brighter Future</h1>
                  <p className='text-light'>Probinar Academy, a product of UltraPro Blockchain., is committed to introducing the world of cryptocurrency and blockchain to everyone. Through our specialized courses, we provide the knowledge and insights needed to understand how these technologies are transforming the future.</p>
                  <button type='button'>Learn Now!</button>
                  </div>
                
                  </div>

             </div>
          </div>
           <div className='academy_content'>
              <div className='container'>
                <div className='acad_abt row'>
                    <div className='acdabt-lft col-lg-6'>
                        <h2>About Probinar</h2>
                        <p>Probinar is passionately dedicated to enlightening individuals about blockchain and cryptocurrency. With just 1% of the world acquainted with cryptocurrencies, our mission is to close this knowledge divide. We provide extensive education to empower individuals, catalyzing the evolution of finance and technology. "Transforming understanding into action."
                        </p>
                        <p>
                        Through our comprehensive educational programs, we empower individuals from all backgrounds with the knowledge and skills necessary to thrive in the digital economy. By equipping everyone with industry-relevant skills and fostering a deeper understanding of blockchain technology, we aim to play a pivotal role in shaping the future of finance and technology.  
                        </p>
                        <button type='button'>Read more</button>
                    </div>
                    <div className='acdabt-rgt col-lg-6'>
                        <img src="images/bg/probi.jpg" alt='probinar' draggable="false"></img>
                    </div>
                </div>
              </div>
           </div>
           <div className='academi'>
             <div className='container'>
               <h2>Explore Our Comprehensive Blockchain Programs</h2>
                <p>We offer in-depth courses covering the essential topics</p>
                <div className='academi_bg my-4 px-2'>
                    <h3 className='text-light'>Fundamentals of Virtual Assets & Blockchain Applications</h3>
                    <p className='text-light'>Gain insights into the transformative potential of these technologies today!</p>
                </div>
             <div className="bns acd">
                    <Slider {...settings}>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4 className='fs-5'>1. What is Blockchain?</h4>
                          <div className='blk_ch'><img src='images/course/1.png' alt='ultrapro info' draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                        <div className="bt-lft">
                        <h4  className='fs-5'>2.  History of Blockchain </h4>
                        <div className='blk_ch'><img src='images/course/2@4x.png' alt='ultrapro info' draggable="false"></img></div>
                        </div>
                        
                    </div>

                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>3. Security and privacy in blockchain networks </h4>
                        <div className='blk_ch'><img src='images/course/3@4x.png' alt='ultrapro info' draggable="false"></img></div>

                        </div>
                    
                    </div>
                    
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>4. Future impact of block chain on industries </h4>
                        <div className='blk_ch'><img src='images/course/4@4x.png' alt='ultrapro info' draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>5. Centralization&De-centralization </h4>
                        
                        <div className='blk_ch'><img src='images/course/5@4x.png' alt='ultrapro info' draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>6.  Types of Algorithm</h4>
                        
                        <div className='blk_ch'><img src='images/course/6@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>7. Types of Blockchain</h4>
                        
                        <div className='blk_ch'><img src='images/course/7@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
              
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>8. Applications of Blockchain [Implemented in Realtime]  </h4>
                        <div className='blk_ch'><img src='images/course/8@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>9. What is a smart contract & Its applications?  </h4>
                        <div className='blk_ch'><img src='images/course/9@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                  
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>10. What is Web-3 & Its applications?

                        </h4>
                        <div className='blk_ch'><img src='images/course/10@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>11.  What is NFT & Trade? </h4>
                        <div className='blk_ch'><img src='images/course/11@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>12. Metaverse & applications</h4>
                        
                        <div className='blk_ch'><img src='images/course/12@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>13.  Virtual currency [Crypto] 

                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/13@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>14.  Types of crypto – how it works 


                        </h4>
                        <div className='blk_ch'><img src='images/course/14@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>15. Legalization in India 

                        </h4>
                        <div className='blk_ch'><img src='images/course/15@4x.png' alt='ultrapro info' draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>16 .Difference between traditional currency & Crypto [ What is coin & Token] 


                        </h4>
                        <div className='blk_ch'><img src='images/course/16@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>17. Difference between coin & token [ Advantages & Disadvantage] 


                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/17@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>18. What is the token standard? 

                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/18@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>19. What is Ticker?


                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/19@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>20. Contract address & Chain id 

                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/20@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>21.  Blockchain explorer 
 

                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/21@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>22.Gas Fees </h4>
                        
                        <div className='blk_ch'><img src='images/course/22@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>23.Top 3 things to know [ Fixed supply, Burning, Minting] </h4>
                        
                        <div className='blk_ch'><img src='images/course/23@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>24.Right coin identification  </h4>
                        <div className='blk_ch'><img src='images/course/24@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>25.What is exchange [how to register – Trade & KYC]?  </h4>
                        
                        <div className='blk_ch'><img src='images/course/25@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>26.Types of exchanges - things can be done using exchange [ Stake, buy & sell] 
                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/26@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>27.How to select the proper exchange & investment?  </h4>
                        
                        <div className='blk_ch'><img src='images/course/27@4x.png' alt='ultrapro info'  draggable="false"></img></div>     
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>28. Wallet creation – types, creation, features 
                        </h4>
                        <div className='blk_ch'><img src='images/course/28@4x.png' alt='ultrapro info' draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>29. Wallet private keys & Secret phrase - Send & receive  </h4>
                        
                        <div className='blk_ch'><img src='images/course/29@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>30. How to create a token independently without a developer? 
                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/30@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                 
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>31. How to market your token? 
                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/31@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>32. Growth of coins [ETH, BNB, BTC] 
                        </h4>
                        <div className='blk_ch'><img src='images/course/32@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>33. Mining & how it works 

                        </h4>
                        <div className='blk_ch'><img src='images/course/33@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>34. Bitcoin halving 
                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/34@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>35. What is supply & demand – how it works?
                        </h4>
                        <div className='blk_ch'><img src='images/course/35@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>36. What is RPC URL? 
 
                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/36@4x.png' alt='ultrapro info '  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>37. Crypto utility trade, how it started vs current situation
                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/37@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>38.Types of scams 
                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/38@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4  className='fs-5'>39. How to pay tax & fake schemes like ICO, Ponzi? 

                        </h4>
                        <div className='blk_ch'><img src='images/course/39@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h4 >40.Crypto friendly nations & how it's functioning
                        </h4>
                        
                        <div className='blk_ch'><img src='images/course/40@4x.png' alt='ultrapro info'  draggable="false"></img></div>
                        </div>
                        
                    </div>
                </Slider>
             </div>
            
             </div>
           </div>
           <div className='acade_prog'>
            <div className='container'>
                   <h2 className=' my-4'>Highlights of the Probinar Program</h2>
                   <div className='row'>
                    <div className='col-lg-4'>
                        <div className='card'>
                            <h4>Comprehensive Learning Levels</h4>
                            <p>Beginner, Intermediate, and Advanced courses tailored to your expertise in blockchain technology.
                            </p>
                        </div>
                    </div>
                        <div className='col-lg-4'>
                        <div className='card'>
                        <h4>Comprehensive Learning Levels</h4>
                            <p>Beginner, Intermediate, and Advanced courses tailored to your expertise in blockchain technology.
                            </p>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='card'>
                        <h4>Practical Application</h4>
                            <p> Gain hands-on experience through interactive exercises that prepare you for real-world blockchain scenarios.

                            </p>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='card'>
                        <h4>Live Q&A Sessions</h4>
                            <p>Engage directly with instructors to deepen your understanding and clarify concepts in real-time.

                            </p>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='card'>
                        <h4>Secure Certification</h4>
                            <p>Receive a Course Completion Certificate secured with NFT ensuring authenticity and recognition of your achievement.</p>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='card'>
                        <h4>Adaptive Learning</h4>
                            <p> Tailors the learning experience to fit individual paces and preferences, ensuring a personalized and effective educational journey.</p>
                        </div>
                      </div>
                   </div>
            </div>
           </div>
           <div className='stage_of_cours'>
              <div className='container'>
                <h3 className=' my-4'>Different levels of Program at Probinar  </h3>
                  <div className='st_of row'>
                     <div className='stof col-lg-4'>
                          <div className='card'>
                             <h5>Beginner Level: Certified Blockchain Essentials </h5>
                             <p>Learn the basics of blockchain technology to start your journey. Perfect for beginners, this program covers essential concepts and practical applications.
                             </p>
                          </div>
                     </div>
                     <div className='stof col-lg-4'>
                     <div className='card'>
                        <h5>Intermediate Level: Certified Blockchain Practitioner
                        </h5>
                        <p>Take your skills further with advanced training in blockchain. It is ideal for those ready to apply blockchain in real-world settings, focusing on practical skills and deeper understanding.</p>
                     </div>
                     </div>
                     <div className='stof col-lg-4'>
                     <div className='card'>
                        <h5>Expert Level: Certified Blockchain Architect</h5>
                        <p>For seasoned professionals, this program dives deep into advanced blockchain architecture and strategic implementation. Prepare to lead blockchain projects and drive innovation. </p>
                     </div>
                     </div>
                  </div>
              </div>
           </div>
           <div className='course-ove'>
              <div className='container'>
                <div className='cour-cont row'>
                <div className='cour-ove col-lg-12'>
                    <h5 className='text-light fs-2 my-4'>Go to the Course Overview</h5>
                  <p className='text-light'>Visit the document to seize the outline of the courses covered by Probinar Academy.
                  </p>
                  <button type='button' > <a href='https://course-pdf.s3.amazonaws.com/COURSE_PDF+(1).pdf' className='course' target='_blank'>View</a></button>
                  </div>
                  
                </div>
           </div>
           </div>
           <div className='course-buy'>
            <div className='container'>
                <div className="cour row mb-4">
                    <div className='cour-lft col-lg-6 mb-4'>
                        <img src='images/bg/nft.jpg' alt='nft'  draggable="false"></img>
                   </div>
                  <div className='cour-rgt col-lg-6'>
                      <h5 className='fs-2 '>Course Completion Certificate with NFT Security</h5>
                        <p>Upon completing our courses, You receive a Course Completion Certificate secured with NFT technology at Probinar Academy. This innovative approach ensures the authenticity and uniqueness of your achievement in blockchain education, enhancing its value and recognition. </p>
                        <button type='button p-2'>Grab Now</button>
                  </div>
              </div>
           </div>
        </div>
    </LayoutTwo>
    </Fragment>
  )
}

export default AcademyContent