import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const HowWorks = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="dg__work__area how__work">
      <h2>Ultrapro Ecosystem</h2>
      <div className="container">
        <div className="bns">
        <Slider {...settings}>
        <div className="bt_ecocont">
          <div className="bt-lft">
               <h3>Explorer: Ultrapro Mainnet Explorer</h3>
               <p>ULTRAPRO Chain powers the decentralized smart contracts platform for UPRO, offering robust blockchain analytics and transaction insights. Explore real-time UPRO price, market cap, and transaction data with our secure and intuitive Block Explorer. Join our community to leverage advanced APIs and comprehensive network status updates for seamless blockchain integration.
               </p>
               <button type="button"><a href="https://ultraproscan.io/" target="_blank">Explore</a></button>
               </div>
               <div className="bt-rgt">
                  <img src="images/bg/bta.png" alt="Ultrapro Mainnet Explorer" draggable="false"></img>
               </div>
           </div>
           <div className="bt_ecocont">
            <div className="bt-lft">
               <h3>Native Cryptocurrency: UPRO</h3>
               <p>UPRO is the native cryptocurrency of Ultrapro Blockchain, powering transactions and governance within our ecosystem with efficiency and transparency. Backed by cutting-edge blockchain technology, UPRO offers secure, fast, and reliable digital transactions worldwide.
               </p>
               </div>
               <div className="bt-rgt">
                  <img src="images/bg/btaa.png" alt="Ultrapro Mainnet Explorer" draggable="false"></img>
               </div>
           </div>

           <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>Crypto Exchange: Ultrapro Exchange </h3>
               <p>Ultrapro Exchange provides a secure platform to trade over 150 cryptocurrencies with features such as 2-factor authentication and API trading. Benefit from seamless fiat-to-crypto transactions and 24/7 customer support, ensuring a straightforward and reliable trading experience.
               </p>
               <button type="button"><a href="https://www.ultraproex.com/" target="_blank">Explore</a></button>
              </div>
              <div className="bt-rgt">
                  <img src="images/bg/exchangeLogo.png" alt="Ultrapro Mainnet Explorer" draggable="false"></img>
               </div>
           </div>
         
           <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>Wallet: Canopus GTX Wallet</h3>
               <p>Discover Canopus GTX Wallet is a pioneering solution in financial technology, offering robust security, rapid transaction speeds, and user-friendly simplicity. Easily manage and stake assets by importing your seed phrase, ensuring a seamless and secure experience. 
               </p>
               <button type="button"><a href="https://www.amazon.com/dp/B0CLKVQX1Q" target="_blank">Download Now</a></button>
               </div>
               <div className="bt-rgt">
                  <img src="images/bg/btc.png" alt="Ultrapro Mainnet Explorer" draggable="false"></img>
               </div>
           </div>
           <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>Crypto Payment Gateway: UPRO Payments</h3>
               <p>UPRO Payments facilitates seamless global crypto payments in Upro with low fees and enhanced security. Supporting iGaming, Forex, and eCommerce, it offers fiat conversions and integrates with multiple wallets for easy transactions.
               </p>
               <button type="button"><a href="https://upropayments.com/" target="_blank">Explore</a></button>
               </div>
               <div className="bt-rgt">
                  <img src="images/bg/btd.png" alt="Ultrapro Mainnet Explorer" draggable="false"></img>
               </div>
           </div>
           <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>DeFi: EenaSwap - eenaswap.finance</h3>
               <p>EenaSwap is a pioneering DeFi platform leveraging ULTRA PRO smart chain technology. It offers high passive income opportunities and community governance through voting on proposals. Investors benefit from no-registration access and seamless wallet integration for instant app usage.</p>
               <button type="button"><a href="https://eenaswap.finance/" target="_blank">Explore</a></button>
               </div>
               <div className="bt-rgt">
                  <img src="images/bg/btf.png" alt="Ultrapro Mainnet Explorer" draggable="false"></img>
               </div>
           </div>
          
           <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>NFT Marketplace: UPRO NFT</h3>
               <p>UPRO, the largest NFT marketplace on the ULTRAPRO blockchain, enables users to mint, buy, and sell unique digital assets effortlessly. Explore top collections and sellers while participating in auctions to acquire exclusive NFTs.</p>
               <button type="button"><a href="https://upronft.com/" target="_blank">Explore</a></button>
               </div>
               <div className="bt-rgt">
                  <img src="images/bg/btg.png" alt="Ultrapro Mainnet Explorer" draggable="false"></img>
               </div>
           </div>
           {/* <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>Staking: Stake UPRO </h3>
               <p>UltraPro Blockchain features a dynamic staking platform with flexible locking periods and attractive monthly rewards for UPRO coin holders. Benefit from residual income opportunities through our multi-level referral program and secure your holdings with our trusted blockchain technology, designed for low fees and high-speed transactions.  </p>
               <button type="button"><a href="https://ultraproscan.io/" target="_blank">Explore</a></button>
               </div>
               <div className="bt-rgt">
                  <img src="images/bg/bta.png" alt="Ultrapro Mainnet Explorer"></img>
               </div>
           </div> */}
         
         
          
           {/* <div className="bt_ecocont">
               <h3>Crypto Education: Probinar</h3>
               <p>Probinar offers premier online blockchain and Cryptocurrency courses catering to beginners and intermediate learners. With expert instructors and hands-on training, we empower individuals to master blockchain technology and its applications effectively.</p>
               <button type="button"><a href="https://www.probinar.in/" target="_blank">Learn Now</a></button>
           </div> */}
     
           {/* <div className="bt_ecocont">
               <h3>Explorer: Ultrapro Mainnet Explorer: </h3>
               <p>ULTRAPRO Chain powers the decentralized smart contracts platform for UPRO, offering robust blockchain analytics and transaction insights. Explore real-time UPRO price, market cap, and transaction data with our secure and intuitive Block Explorer. Join our community to leverage advanced APIs and comprehensive network status updates for seamless blockchain integration.
               </p>
               <button type="button"><a href="https://ultraproscan.io/" target="_blank">Explore</a></button>
           </div> */}
           {/* <div className="bt_ecocont">
               <h3>DApps</h3>
              
               <p>DApps lineup includes Canopus GTX Wallet for secure asset management, UPRO NFT Marketplace for digital collectibles, UPRO Coin for decentralized finance, our cutting-edge Defi platform, Ucoinpay for seamless crypto payments, and Canopro Exchange for peer-to-peer trading.  </p>
              
           </div>
           <div className="bt_ecocont">
               <h3>Lending: Ultrapro Mainnet Explorer: </h3>
               <p>Get ready for our upcoming lending platform, designed to offer secure, flexible loans using your crypto assets as collateral. Experience seamless borrowing and lending with competitive interest rates and transparent terms.</p>
             
           </div>
           <div className="bt_ecocont">
               <h3>B2 Casino</h3>
              
               <p>Get ready for the ultimate gaming experience at B2Casino, our upcoming online casino platform. Soon, you can immerse yourself in thrilling games and unlock enormous rewards with UPRO. Stay tuned for the launch and elevate your gaming adventure! </p>
             
           </div> */}
             <div className="bt_ecocont">
             <div className="bt-lft">
               <h3>PRO20 - USDT</h3>
              
               <p>We have PRO20-based stablecoin issued by Tether on robust foundations, offering rapid issuance and minimal handling fees. It has gained significant user popularity since its inception.               </p>
               <button type="button"><a href="https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers" target="_blank">Explore</a></button>
             </div>
             <div className="bt-rgt">
                  <img src="images/bg/bth.png" alt="Ultrapro Mainnet Explorer" draggable="false"></img>
               </div>

           </div>
           {/* <div className="bt_ecocont">
               <h3>Colour Gaming Platform: B2Bet</h3>
               <p>Prepare for the excitement of B2Bet, our upcoming platform where color prediction meets the power of UPRO for rewarding gameplay. Soon, you'll experience seamless features and the chance to win big. Stay tuned and get ready to elevate your gaming adventure at B2Bet!</p>
               
           </div>

         

           <div className="bt_ecocont">
               
               <h3>Token: UBIT Token</h3>
               <p>Introducing Ubit Token, created using Pro 20 Stability Coin, ensuring unparalleled stability and reliability. Designed for seamless transactions and robust performance. Ubit Token sets a new standard in digital finance.</p>
             
           </div>
           <div className="bt_ecocont">
               <h3>UPRO Bazaar: UBIT Token</h3>
               <p>Explore Uprobazzar, the upcoming platform revolutionizing online shopping with UPRO. Convert your UPRO tokens into vouchers for leading brands like Amazon, Flipkart, Uber, and more. Seamlessly deposit UPRO from your wallet to unlock a diverse range of shopping possibilities and rewards, all in one convenient platform.</p>
               <button type="button"><a href="https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers" target="_blank">Explore</a></button>
           </div>
           <div className="bt_ecocont">
               <h3>Pro network
               </h3>
               
               <p>Our scalable solution for UltraPro MainNet ensures unlimited scalability, low resource consumption, high security, and customizability, facilitating advanced blockchain applications.</p>
               <button type="button"><a href="https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers" target="_blank">Explore</a></button>
           </div> */}
    </Slider>
        </div>
        {/* <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="thumb">
              <img
                src={process.env.PUBLIC_URL + "/images/about/4a.png"}
                alt="computer images"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
            <div className="content">
            <h2>Blockchain
            Powering the Next Digital Era</h2>
              <h6>
                cryptocurrency is a digital asset designed to work as a medium
                of exchange
              </h6>
             
             
              <p>
              Step into a world where Ultrapro breaks down borders, transactions surge effortlessly, and fortunes unfold daily. As a pinnacle of financial innovation, Ultrapro transforms the financial landscape. Explore a realm where boundaries blur, transactions thrive, and daily fortunes await
              </p>
              <Link
                className="slide__btn dg__btn"
                to={process.env.PUBLIC_URL + "/contact"}
              >
                JOIN WITH US
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HowWorks;
