import React from "react";


const MobileWidgets = () => {
  return (
    <div className="offcanvasWidgetArea">
      <div className="offCanvasContactWidget">
        <div className="headerContactInfo">
        
        </div>
      </div>
      
    </div>
  );
};

export default MobileWidgets;
