
import React, { useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import emailjs from 'emailjs-com';

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Link, useHistory } from 'react-router-dom';


const MobileNavMenu = () => {
  const history = useHistory();
  const handleClick = () => {

    const token = localStorage.getItem('token');
    if (token) {
      history.push('/live-online-expo');
    } else {
      history.push('/online-expo');
    }
  };

 

  useEffect(() => {
    emailjs.init("uhuWsxq6yK7Z6XCJs");
  }, []);

  const [thank, showThank] = useState(false);
 
  const modb = () => {

    showThank(false)
  }

 


  const location = useLocation();
  function getInitialActiveIndex(pathname) {
    switch (pathname) {
      case process.env.PUBLIC_URL + "/":
        return 0;
      case process.env.PUBLIC_URL + "/about-us":
        return 1;
      case process.env.PUBLIC_URL + "/blog":
        return 2;
      case process.env.PUBLIC_URL + "/enhanced-enterprises":
        return 3;
      default:
        return -1; // No active item by default
    }
  }
  const [active, setActive] = useState(getInitialActiveIndex(location.pathname)); // Initial active state, defaulting to the first item

  const activecl = (index) => {
    setActive(index); // Function to set the active index on click
  }
  return (
    <>
      <Modal
        show={thank}

        className="tradmod"
        backdrop="static"
        centered="bool"
        size='md'
      >
        <Modal.Header closeButton onClick={modb}>
          <strong>Ultrapro Online Expo</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="to_mod">
            <div className="to_fa">
              <strong>Thank You</strong>
              <p>
                Thank you for registering! Expo details have been shared in our

              </p>
              <ul className="soci">
                <li className="what"><a href="https://whatsapp.com/channel/0029VacgXM960eBi0TtO9542" target="_blank">WhatsApp</a></li>
                <li className="tele"><a href="https://t.me/Ultraproblockchainonlineexpo" target="_blank">Telegram</a></li>

              </ul>
              <p> Please join us there for updates
                and information</p>
              <button type="button" className="bton" onClick={modb}>
                Home
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    <nav className="offcanvasNavigation" id="offcanvas-navigation">
      <ul>
        <li className="menuItemHasChildren">
          <Link to={process.env.PUBLIC_URL + "/"} className={`${active === 0 ? 'active' : ''}`}
        onClick={() => activecl(0)}>Home</Link>
          
        </li>
        <li> <Link to={process.env.PUBLIC_URL + "/about-us"} className={`${active === 1 ? 'active' : ''}`}
        onClick={() => activecl(1)}>
              About us
            </Link></li>
        <li className="menuItemHasChildren">
          <a href="javascript:void(0);">  Ecosystem </a>
        
          <ul className="dropdown subMenu">
          <li>
                <a href="https://ultraproscan.io/" target="_blank"> Explorer </a>
              </li>
              <li>
                <a href="https://upronft.com/" target="_blank">UPRO NFT</a>
              </li>
            
              <li>
                <a href="https://www.amazon.com/dp/B0CLKVQX1Q" target="_blank">Canopus GTX wallet </a>
              </li>
              <li>
                <a href="https://upropayments.com/" target="_blank">UPRO Payments </a>
              </li>
              <li>
                <a href="https://eenaswap.finance/" target="_blank">DeFi</a>
              </li>
              <li>
                <a href="https://canopro.com/stake" target="_blank">Staking        </a>
              </li>
              
             
              <li>
                <a href="https://canopro.com/" target="_blank">Ultrapro Exchange </a>
              </li>
         
              <li>
                <a href="https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers" target="_blank">Pro-20 USDT </a>
              </li>
          </ul>
        </li>

        <li className="menuItemHasChildren">
        <Link to={process.env.PUBLIC_URL + "/blog"} className={`${active === 2 ? 'active' : ''}`}
        onClick={() => activecl(2)}>
          Blog 
          </Link>
         
        </li>
        <li className="menuItemHasChildren">
        <a href="javascript:void(0);">
          Token 
          </a>
          <ul className="dropdown subMenu">
            <li>
             <Link to="https://ultraproscan.io/token/0x1F5712B75B01FF9bD337811b56E3c9f1257CEa86/token-transfers"> UBIT</Link> 
            </li>
            <li>
            <Link to="https://ultraproscan.io/token/0x229268FEeaC6C6D620E09F09825a6f3CdA9ec17a/token-transfers"> Honey</Link> 
            </li>
            <li>
            <Link to="https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers">USDT</Link> 
            </li>
            <li>
            <Link to="https://ultraproscan.io/token/0x229268FEeaC6C6D620E09F09825a6f3CdA9ec17a/token-transfers">BUSD</Link> 
            </li>
            <li>
            <Link to="https://ultraproscan.io/token/0xf29f34DF858d971Fc148d2db40647769676606dA/token-transfers">UPRONFT</Link> 
            </li>
          </ul>
        </li>
        <li className="drop">
        <a href="javascript:void(0);" onClick={handleClick}>Ultrapro's Expo</a>
            
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/enhanced-enterprises"} className={`${active === 3 ? 'active' : ''}`}
        onClick={() => activecl(3)}>
              Enhanced Enterprises
            </Link>
          </li>
       
      </ul>
    </nav>
    </>
  );
};

export default MobileNavMenu;
