import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import { LoopRepeat } from "three";
import { useState, useEffect } from "react";
import { config } from '../config/config'
import { Helmet } from "react-helmet";
import axios from "axios";
const Service = () => {
  const url = "https://ultrapro-info-images.s3.amazonaws.com/bike.mp4";

  const purl = "https://youtu.be/1dnL27wC9hI?si=TCEXqVxkgHy5--zJ";
  const rurl = 'https://ultrapro-info-images.s3.amazonaws.com/video.mov';
  const yurl = "https://ultrapro-info-images.s3.amazonaws.com/videoc.mp4";

  var settingsv = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,


    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 3000,
    cssEase: "linear",
    rtl: "false",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var set = {
    dots: false,

    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [playing, setPlaying] = useState(false);
  const [play, setPlay] = useState(false);
  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePlays = () => {
    setPlay(true);
  };

  const [metaTitle, setMetaTitle] = useState();
  const metaTitles = async () => {
    try {
      const getMetaTitle = await axios.get(`${config.BACKEND_HOST}/metaTagRouter/meta-tag?page=Enhanced-Enterprises`);
     
      setMetaTitle(getMetaTitle.data.data.title);
    } catch (error) {

    }
  }
  useEffect(() => {
    metaTitles();
  }, [])

  return (
    <Fragment>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>

      <LayoutTwo theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="Enhanced Enterprises" />
        {/* service content */}
        <div className="company content">
          <div className="container">
            <div className="comp row">
              <div className="comp_lft col-md-6">
                <ReactPlayer
                  className='react-player'
                  url={url}
                  width='100%'
                  height='100%'
                  controls
                />
              </div>
              <div className="comp_rgt col-md-6">
                <h2>Tapy Maax 2019</h2>
                <p>Tapy Maax is a pioneering electric bike manufacturing company, established as a public limited company with a strong commitment to reducing pollution through sustainable transportation solutions. Founded by Nagarajan Narayanasamy, Tapy Maax focuses on producing innovative green energy bikes that offer eco-friendly alternatives to traditional vehicles. Driven by a vision to promote environmental conservation and reduce carbon footprints, our founder's dedication to green energy has propelled Tapy Maax to the forefront of the electric bike industry. Under Narayanasamy's leadership, the company continues to innovate, providing efficient, sustainable, and stylish transportation options for a cleaner future.
                </p>
              </div>
            </div>
          
            <div className="comp row">
              <div className="comp_lft col-md-6">
                <ReactPlayer
                  className='react-player'
                  url={purl}
                  width='100%'
                  height='100%'
                  controls
                />
              </div>
              <div className="comp_rgt col-md-6">
                <h2>Probinar</h2>
                <p>Probinar is an Edutech company focused on educating individuals about blockchain and cryptocurrency. With only 1% of the global population aware of cryptocurrencies, our founder mission is to bridge this knowledge gap. We offer comprehensive education to empower individuals and shape the future of finance and technology. </p>
              </div>
            </div>
          
          </div>
        </div>
        <div className="cmp">
          <div className="container">
            <h1>EVENTS</h1>
            <Slider {...settings} className="slidea">
              <div className="im_c">
                <img src="images/events/company.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/duba.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/meet.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dubi.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dbs.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/award.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/cryp.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dub.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/imd.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/ime.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imf.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imi.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imj.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imk.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/iml.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imm.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imn.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imz.jpg" alt="event" draggable="false"></img>
              </div>
            </Slider>
            <Slider {...set} className="slidea">
              <div className="im_c">
                <img src="images/events/company.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/duba.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/meet.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dubi.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dbs.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/award.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/cryp.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dub.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/imd.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/ime.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imf.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imi.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imj.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imk.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/iml.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imm.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imn.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imz.jpg" alt="event" draggable="false"></img>
              </div>

            </Slider>
            <Slider {...settings} className="slidea">
              <div className="im_c">
                <img src="images/events/company.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/duba.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/meet.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dubi.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dbs.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/award.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/cryp.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/dub.webp" alt="event" draggable="false"></img>
              </div>

              <div className="im_c">
                <img src="images/events/imd.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/ime.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imf.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imi.webp" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imj.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imk.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/iml.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imm.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imn.jpg" alt="event" draggable="false"></img>
              </div>
              <div className="im_c">
                <img src="images/events/imz.jpg" alt="event" draggable="false"></img>
              </div>
            </Slider>
          </div>
        </div>
        <div className="videocont">
          <div className="container">
            <div className=" video_conts">
              <Slider {...settingsv}>
                <div className="vidc">
                  <h3>Achievers</h3>
                  <div className="vid">
                    {!playing ? (
                      <div className="implay">
                        <img src="images/events/awards.jpg" alt="Thumbnail" draggable="false" />
                        <button className="play-button" onClick={handlePlay}>
                          <img src="images/events/play.svg" alt="play" draggable="false"></img>
                        </button>
                      </div>
                    ) : null}
                    {playing ? (
                      <ReactPlayer
                        className='react-player'
                        url={rurl} jj

                        width='100%'
                        height='100%'
                        controls
                        playing={true}

                      />
                    ) : null}
                  </div>
                </div>
                <div className="vidc">
                  <h3>Ultrapro promoters meet India</h3>
                  <div className="vid">
                    {!play ? (
                      <div className="implay">
                        <img src="images/events/club.jpg" alt="Thumbnail" draggable="false" />
                        <button className="play-button" onClick={handlePlays}>
                          <img src="images/events/play.svg" alt="play" draggable="false"></img>
                        </button>
                      </div>
                    ) : null}
                    {play ? (
                      <ReactPlayer
                        className='react-player'
                        url={yurl}
                        width='100%'
                        height='100%'
                        controls
                        playing={true}

                      />
                    ) : null}

                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </LayoutTwo>
    </Fragment>
  );
};

export default Service;
