export const ApiServices = {
    ULTRA_BLOG_LIST: '/blog/blogList',
    ULTRA_ANNOUNCEMENT_LIST: '/blog/announcementList',
    ULTRA_NEWS_LIST: '/blog/newsBlogList',

    USER_CATAGORY_SERVER: '/blog/category',
    USER_CAREER_SERVER: '/ultraproCareer/jobList',
    USER_GETAPPLICATION_SERVER: '/ultraproCareer/application/submit',
    ULTRA_PRO_EXPO: '/ultraAdminRouter/ultraproExpoLeadeSheet',
    ULTRAPRO_INFO_USER_LOGIN: "/ultraAdminRouter/ultraproInfoUserLogin",
    ULTRA_PRO_SPECIFIC_BLOG_ID: '/blog/blogSpecificList',
    ULTRA_PRO_SPECIFIC_ANNOUNCEMENT_BLOG_ID:'/blog/announcementSpecificList',
    ULTRA_PRO_SPECIFIC_NEWS_BLOG_ID: '/blog/blogNewsSpecificList',
    SUB_NEWS_ROUTE: '/news',
    SUB_ANNOUNCEMENT_ROUTE: '/announcement',
    SUB_LEARN_ROUTE: '/learn'
}