import React, { useEffect, useState } from "react";
import BlogSidebar from "../../components/blog/BlogSidebar";
import { Link } from "react-router-dom";
import { FaRss, FaPinterestP, FaVimeoV, FaGoogle } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { config } from "../../config/config";
import { ApiServices } from "../../ApiServices/AllApi";
import { Helmet } from "react-helmet";
import { urlToTitleFormat } from '../../utils'; // Import the utility function

const BlogPostContent = () => {

  const { title } = useParams();

  const id = window.location.pathname.split('/')[3]
  const [blogDetails, setBlogDetails] = useState({});
  const [newsStatus, setNewsStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const storedCount = localStorage.getItem('blogId');


  // Function to fetch the blog post details
  const getTheBlogPost = async () => {
    try {

      const response = await axios.post(`${config.BACKEND_HOST}${ApiServices.ULTRA_PRO_SPECIFIC_BLOG_ID}`, { blogId: urlToTitleFormat(title) });
  
      if (response.data.status) {
        setBlogDetails(response.data.data);
      } else {

      }
    } catch (error) {
      console.error('Error fetching blog post:', error);
    } finally {
      setLoading(false);
    }
  };

  const getTheAnnouncementBlogPost = async () => {
    try {

      const response = await axios.post(`${config.BACKEND_HOST}${ApiServices.ULTRA_PRO_SPECIFIC_ANNOUNCEMENT_BLOG_ID}`, { blogId: urlToTitleFormat(title) });
  
      if (response.data.status) {
        setBlogDetails(response.data.data);
      } else {

      }
    } catch (error) {
      console.error('Error fetching blog post:', error);
    } finally {
      setLoading(false);
    }
  };


  const getTheNewsBlogPost = async () => {
    try {

      const response = await axios.post(`${config.BACKEND_HOST}${ApiServices.ULTRA_PRO_SPECIFIC_NEWS_BLOG_ID}`, { blogId: urlToTitleFormat(title) });
  
      if (response.data.status) {
        setBlogDetails(response.data.data);
      } else {

      }
    } catch (error) {
      console.error('Error fetching blog post:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {

      const newsResponse = await axios.get(`${config.BACKEND_HOST + ApiServices.ULTRA_NEWS_LIST}`);
      setNewsStatus(newsResponse.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  useEffect(() => {
    fetchData();
    getTheBlogPost();
    getTheAnnouncementBlogPost();
    getTheNewsBlogPost();
  }, [id]);


  const utcToNormalTime = (utcString) => {
    const date = new Date(utcString);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    return { date: formattedDate, time: formattedTime };
  };

  const cleanHtmlString = (str) => {

    return str ? str.replace(/^"|"$/g, '').replace(/\\/g, '') : '';
  };

  const saveBlogId = (blogId) => {
    localStorage.setItem('blogId', blogId);

  };

  if (loading) return <div>Loading...</div>;



  return (



    //           {/* Start Blog Author */}

    //           {/* End Blog Author */}
    //           {/* Start Blog Comment */}

    //           {/* End Blog Comment */}
    //           {/* Start Comment Form */}

    //           {/* End Comment Form */}
    //         </div>
    //       </div>
    //       <div className="col-lg-4 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
    //         {/* sidebar */}
    //         <BlogSidebar />
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className='bl_det'>
      <Helmet>
        <title>{blogDetails.title}</title>
      </Helmet>

      <div className='container'>
        <div className="bldetls row">
          <div className='bl_ds col-lg-9'>


            <h1>{blogDetails.title}</h1>

            <span className="d-block text-dark text-end my-4">
              {blogDetails.createdAt && utcToNormalTime(blogDetails.createdAt).date}
            </span>
            <img src={blogDetails.image} alt={blogDetails.title} draggable="false" />

            <p dangerouslySetInnerHTML={{ __html: cleanHtmlString(blogDetails.ckEditerContent) }} />
          </div>



          <div className="news_blg col-lg-3">

            <h2 className="fs-2 lh-base mb-4 ">Latest News</h2>
            {newsStatus.map((data, index) => (
              <div className="news_con   row align-items-center">
                <a
                  href={`${process.env.PUBLIC_URL}/blog${ApiServices.SUB_NEWS_ROUTE}/${data.title.toLowerCase().replace(/\s+/g, '-')}`} onClick={() => saveBlogId(data._id)} className="row" >
                  <p className="col-6  fs-5">{data.title}</p>
                  <div className="newsim col-6">
                    <img src={data.image} alt={data.title} className="w-100 object-fit-cover h-auto rounded"></img>
                  </div>
                </a>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div >


  );
};

export default BlogPostContent;
