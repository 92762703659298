import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ParticleTwo from "../particles/ParticleTwo";
import ReactPlayer from 'react-player';
import MouseParallax from "./Parac";
import CanvasAnimation from "../../pages/Case";

const HeroSliderFourSingle = ({ data }) => {

  return (
    <div
      className="slide d-flex align__center poss--relative"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL +
          data.backgroundImage})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`
      }}
    >
      <div className="silde_vid">

        <div className="slivid">
          <CanvasAnimation />
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center slidvid">
          <div className=" d-flex align-items-center row flex-wrap-wrap trde">
            <div className="slide__inner ">

              <h1><span className="gre">{data.titlea}</span>{data.tilespan}</h1>
              <h2>{data.title}</h2>
              <p>{data.text}</p>
              <Link
                className="slide__btn dg__btn btn--white"
                to={process.env.PUBLIC_URL + data.url}
              >
                Learn More
              </Link>
            </div>
            <div className="sli_con col-lg-6">

              <div className="sli_im">
                <div class="loader">
                  <div class="spinner">•</div>
                </div>
                <img src="images/bg/kakas.png" alt="Ultrapro Blockchain" draggable="false"></img>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* particle */}

    </div>
  );
};

HeroSliderFourSingle.propTypes = {
  data: PropTypes.object
};

export default HeroSliderFourSingle;
