import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Header from "../containers/headers/Header";
import BrandLogoTwo from "../containers/brand-logos/BrandLogoTwo";
import Newsletter from "../components/newsletters/Newsletter";
import Footer from "../containers/footers/Footer";
import { Link } from "react-router-dom";

const LayoutTwo = ({ children, theme }) => {
  const [ads, setAds] = useState(false);

  const show = () => {
    setAds(true);
  };

  const pathSegment = window.location.pathname.split('/')[2];


  return (
    <Fragment>
      <Header theme={theme} />
      {children}
      {/* brand logo */}
      <BrandLogoTwo />
      {/* newsletter */}
      <Newsletter />
      <Footer theme={theme} />

      {/* Conditional rendering based on pathSegment */}
      {pathSegment === 'news' || pathSegment === 'learn' || pathSegment === 'announcement' ? (
        <Fragment>
          <div className={ads ? "announce ads" : "announce"}>
            <span className="ann" onClick={show}><img src="../../images/bg/clos.svg" alt="close" draggable="false" /></span>
            <Link to="/online-expo">
              <img src="../../images/bg/online_expo.png" alt="announcement" draggable="false" />
            </Link>
          </div>
          <ul className='ftms'>
            <li><a href='https://www.facebook.com/ultraproupdates/' target='_blank'><img src='../../images/bg/fb.svg' alt='fb' draggable="false" /></a></li>
            <li><a href='https://twitter.com/Ultraproupdates' target='_blank'><img src='../../images/bg/twi.svg' alt='twitter' draggable="false" /></a></li>
            <li><a href='https://www.youtube.com/@ultraproupdates' target='_blank'><img src='../../images/bg/utube.svg' alt='youtube' draggable="false" /></a></li>
            <li><a href='https://t.me/ultrapro_announcement' target='_blank'><img src='../../images/bg/teleg.svg' alt='Telegram' draggable="false" /></a></li>
            <li><a href='https://www.instagram.com/ultraproupdates/' target='_blank'><img src='../../images/bg/fins.svg' alt='Instagram' draggable="false" /></a></li>
            <li><a href='https://www.linkedin.com/company/ultrapro-blockchain/' target='_blank'><img src='../../images/bg/in.svg' alt='Linked In' draggable="false" /></a></li>
          </ul>
        </Fragment>
      ) : (
        <Fragment>
          <div className={ads ? "announce ads" : "announce"}>
            <span className="ann" onClick={show}><img src="images/bg/clos.svg" alt="close" draggable="false" /></span>
            <Link to="/online-expo">
              <img src="images/bg/online_expo.png" alt="announcement" draggable="false" />
            </Link>
          </div>
          <ul className='ftms'>
            <li><a href='https://www.facebook.com/ultraproupdates/' target='_blank'><img src='images/bg/fb.svg' alt='fb' draggable="false" /></a></li>
            <li><a href='https://twitter.com/Ultraproupdates' target='_blank'><img src='images/bg/twi.svg' alt='twitter' draggable="false" /></a></li>
            <li><a href='https://www.youtube.com/@ultraproupdates' target='_blank'><img src='images/bg/utube.svg' alt='youtube' draggable="false" /></a></li>
            <li><a href='https://t.me/ultrapro_announcement' target='_blank'><img src='images/bg/teleg.svg' alt='Telegram' draggable="false" /></a></li>
            <li><a href='https://www.instagram.com/ultraproupdates/' target='_blank'><img src='images/bg/fins.svg' alt='Instagram' draggable="false" /></a></li>
            <li><a href='https://www.linkedin.com/company/ultrapro-blockchain/' target='_blank'><img src='images/bg/in.svg' alt='Linked In' draggable="false" /></a></li>
          </ul>
        </Fragment>
      )}
    </Fragment>
  );
};

LayoutTwo.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.string
};

export default LayoutTwo;
