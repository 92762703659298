import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ image }) => {
  const pathSegment = window.location.pathname.split('/')[2];

  return (
    <div className="logo">
      {pathSegment === 'news' || "learn" || "announcement" ? (
        <Link to={'/'}>
          <img
            src="../../images/logo/2.png"
            alt="Ultrapro info"
            className="img-fluid"
            draggable="false"
          />
          <span className="lgo">
            <img
              src="../../images/logo/2aa.png"
              alt="Ultrapro info"
              className="img-fluid"
              draggable="false"
            />
          </span>
        </Link>
      ) : (
        <Link to={'/'}>
          <img
            src="images/logo/2.png"
            alt="Ultrapro info"
            className="img-fluid"
            draggable="false"
          />
          <span className="lgo">
            <img
              src="images/logo/2aa.png"
              alt="Ultrapro info"
              className="img-fluid"
              draggable="false"
            />
          </span>
        </Link>
      )}
    </div>
  );
};

Logo.propTypes = {
  image: PropTypes.string
};

export default Logo;
