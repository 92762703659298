import React from 'react'
import MetaTags from "react-meta-tags";
import LayoutTwo from "../../layouts/LayoutTwo";
import Modal from "react-bootstrap/Modal";
import { Link, useHistory } from "react-router-dom"

import { useState, useEffect } from "react";
import { EmailSend, loginWithEmailOrPhone } from "../../Yup";
import emailjs from 'emailjs-com';
import toast from "react-hot-toast";
import { config } from "../../config/config";
import { ApiServices } from "../../ApiServices/AllApi";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CountdownToDate from './sub-components/Count';
import ParticleTwo from "../particles/ParticleTwo";
import { Spinner } from 'react-bootstrap';
import { Helmet } from "react-helmet";

const OnlineExpo = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [showAll, setShowAll] = useState(false)

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsAppNumber: '',
    age: "",
    district: "",
    zipCode: "",
    state: "",
    country: "",
    interested: ""
  });
  const [Zip, setZip] = useState([])
  const [zipOptions, setZipOptions] = useState([]);
  const [ulraproUserLogin, setUlraproUserLogin] = useState({
    email: "",
    whatsAppNumber: '',

  });

  useEffect(() => {
    emailjs.init("uhuWsxq6yK7Z6XCJs");
  }, []);

  const [show, showNext] = useState(false);
  const [thank, showThank] = useState(false);
  const modClick = () => {
    showNext(true)
  }
  const modbClose = () => {
    showNext(false)
    // showThank(true)
  }
  const modb = () => {

    showThank(false)
  }

  const handleChange = (e) => {

    const { name, value } = e.target;
    let sanitizedValue = value;
    let spaceError = "";

  
    if (name === "zipCode" && sanitizedValue.length === 6) {
      handleZipAPI(sanitizedValue);
    }

    if (name === "name") {
      sanitizedValue = value.trimStart();
    }


    if (name !== "name") {
      sanitizedValue = sanitizedValue.replace(/\s/g, "");
    }
    if (name === "selectedDistrict") {
      const selectedOption = zipOptions.find(option => option.district === sanitizedValue);
      if (selectedOption) {
       
      }
    }


    setFormData({ ...formData, [name]: sanitizedValue });
    setErrors({ ...errors, [`${name}SpaceError`]: spaceError }); // Set space error separately for each field

    EmailSend
      .validateAt(name, { [name]: sanitizedValue })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "", [`${name}SpaceError`]: spaceError }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message, [`${name}SpaceError`]: spaceError }));
      });
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    if (name === 'resume') {
      // No need to validate file inputs onBlur
      return;
    }

    // Validate other fields using Yup schema
    EmailSend
      .validateAt(name, { [name]: formData[name] })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      });
  };

  const handleOnlyLetters = (e) => {
    if (!/^[a-zA-Z\s.]*$/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleOnlyNumbers = (e) => {
    const validKeys = /^[0-9\s.]$/;
    if (!validKeys.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  const handleZipAPI = async (pinCode) => {
    setLoading(true)

    const response = await axios.get(`https://api.postalpincode.in/pincode/${pinCode}`);

    if (response.data && response.data[0].Status === "Success" && response.data[0].PostOffice.length > 0) {
      setLoading(false)
      const district = response.data[0].PostOffice[0].District;
      const state = response.data[0].PostOffice[0].State;
      const country = response.data[0].PostOffice[0].Country;
      setShowAll(true)
      setErrors((prevErrors) => ({
        ...prevErrors,
        district: "",
        state: "",
        country: ""
      }));
      setFormData((prev) => ({
        ...prev,
        district: district,
        state: state,
        country: country
      }));
    } else {
      setShowAll(true)
      setLoading(false)
      toast('No records found In Indian Country If you Other Country Type Manually')
    }
    // const options = results.slice(0, -1)
    // console.log(options, "+_+_+_+_+_+");
    // setZipOptions(options);
  }


  const handleSubmit = async (data) => {
    // e.preventDefault();

    const emptyFields = {};
    const labelMapping = {
      name: "Name",
      email: "Email",
      age: "Age",
      whatsAppNumber: "What'sApp Number",
      district: "District",
      interested: "Interested",
      zipCode: "ZIP Code",
      state: "State",
      country: "Country"


    };

    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });


    setErrors({ ...errors, ...emptyFields })


    // If there are empty fields, stop further validation
    if (Object.keys(emptyFields).length > 0) {
      return;
    }


    try {

      // Proceed with schema validation
      await EmailSend.validate(formData, { abortEarly: false });
      const response = await axios.post(`${config.BACKEND_HOST + ApiServices.ULTRA_PRO_EXPO}`, formData);

      if (response.data.status) {
        toast.success('successfully sent!', {
          position: "top-right"
        })
        showNext(false)
        showThank(true)
        setFormData({
          name: "",
          email: "",
          whatsAppNumber: '',
          age: "",
          district: "",
          interested: "",
          zipCode: "",
          state: "",
          country: ""
        });
      } else {
        toast.error(response.data.message, {
          position: "top-right"
        })
      }
      setErrors({});


    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const location = useLocation();
  useEffect(() => {
    // Check the path and decide whether to open the modal
    if (location.pathname === '/live-online-expo') {
      showNext(true)
    } else {
      showNext(false)
    }
  }, [location]);

  const [chat, setChat] = useState(false);
  const showr = () => {
    setChat(true)
  }

  const showl = () => {
    setChat(false)
  }



  const handleUserLoginBlur = (e) => {
    const { name } = e.target;

    if (name === 'name') {
      // No need to validate file inputs onBlur
      return;
    }

    loginWithEmailOrPhone
      .validateAt(name, { ...ulraproUserLogin, [name]: ulraproUserLogin[name] })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      });
  };

  const handleUserLoginChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.trim(); // Trim whitespace from input value
    let spaceError = "";

    if (name === "name") {
      sanitizedValue = value.trimStart();
    }

    setUlraproUserLogin({ ...ulraproUserLogin, [name]: sanitizedValue });
    setErrors({ ...errors, [`${name}SpaceError`]: spaceError });

    loginWithEmailOrPhone
      .validateAt(name, { ...ulraproUserLogin, [name]: sanitizedValue })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "", [`${name}SpaceError`]: spaceError }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message, [`${name}SpaceError`]: spaceError }));
      });
  };




  const signinSubmit = async () => {
    const emptyFields = {};
    const labelMapping = {
      email: "Email",
      whatsAppNumber: "WhatsApp Number",
    };

    const isEmailOrPhoneProvided = ulraproUserLogin.email || ulraproUserLogin.whatsAppNumber;

    if (!isEmailOrPhoneProvided) {
      emptyFields.email = "Email or WhatsApp Number is required";
    }

    setErrors({ ...errors, ...emptyFields });

    if (Object.keys(emptyFields).length > 0) {
      return;
    }

    try {
      const response = await axios.post(`${config.BACKEND_HOST + ApiServices.ULTRAPRO_INFO_USER_LOGIN}`, ulraproUserLogin);
    

      const tk = response.data.data;
      if (response.data.status) {
        toast.success(response.data.message, {
          position: "top-right"
        });

        // Save the token and expiration time in local storage
        const token = response.data.token;
      
        const expirationTime = new Date().getTime() + 3600 * 1000; // 1 hour from now
        localStorage.setItem('token', token);
        localStorage.setItem('name', tk.name);
        localStorage.setItem('tokenExpiration', expirationTime);
        localStorage.setItem('email', tk.email);
        localStorage.setItem('_id', tk._id);
        // Set a timeout to handle token expiration


        showNext(false);
        history.push('/live-online-expo');
        setUlraproUserLogin({ email: "", whatsAppNumber: '' });
      } else {
        toast.error('Not submitted!', {
          position: "top-right"
        });
      }
      setErrors({});
    } catch (error) {
      console.error(error);
      toast.error('Server error. Please try again later.', {
        position: "top-right"
      });
      setErrors({});
    }
  };

  const [metaTitle, setMetaTitle] = useState();
  const metaTitles = async () => {
    try {
      const getMetaTitle = await axios.get(`${config.BACKEND_HOST}/metaTagRouter/meta-tag?page=online-expo`);
     
      setMetaTitle(getMetaTitle.data.data.title);
    } catch (error) {

    }
  }

  useEffect(() => {
    metaTitles();
  }, [])
  return (
    <>
    <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <Modal
        show={thank}

        className="tradmod"
        backdrop="static"
        centered="bool"
        size='md'
        id="3"
      >
        <Modal.Header closeButton onClick={modb}>
          <strong>Ultrapro Online Expo</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="to_mod">
            <div className="to_fa">
              <strong>Thank You</strong>
              <p>
                Thank you for registering! Expo details have been shared in our

              </p>
              <ul className="soci">
                <li className="what"><a href="https://whatsapp.com/channel/0029VacgXM960eBi0TtO9542" target="_blank">WhatsApp</a></li>
                <li className="tele"><a href="https://t.me/Ultraproblockchainonlineexpo" target="_blank">Telegram</a></li>

              </ul>
              <p> Please join us there for updates
                and information</p>
              <button type="button" className="bton" onClick={modb}>
                <Link to="/"> Home</Link>

              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <MetaTags>
        <title>online-expo</title>
        <meta
          name="description"
          content="About page of React JS Crypto Currency Template."
        />
      </MetaTags> */}
      <LayoutTwo theme="white">
        {/* breadcrumb */}
        <div
          className="ht__bradcaump__area cud"
          style={{
            background: `rgba(0, 0, 0, 0) url(${process.env.PUBLIC_URL +
              "/images/bg/7.jpg"}) repeat scroll center center/105% 100%`
          }}
        >
          <div className="ht__bradcaump__container  htbrd">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="bradcaump__inner text-center">
                    <div className='brds'>
                      <img src='images/bg/expo_bg.png' alt='expo' draggable="false"></img>
                      <div class="loader">
                        <div class="spinner">•</div>
                      </div>
                      <div className='br_content'>
                        <h1>ONLINE EXPO</h1>
                        <p>1ST SEPTEMBER 2024</p>

                      </div>
                      <div className='cdd'>
                        <CountdownToDate targetDate="September 1, 2024" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* particle */}
          <ParticleTwo />
        </div>

        <div className='expo-form tradmod'>
          <div className='container'>
            <div className='expo-contents row'>
              <div className='expo-im col-lg-6'>
                <img src="images/bg/expo.webp" alt='expoform image' draggable="false"></img>
                <div className='expo-cont'>

                  <div>
                    <CountdownToDate targetDate="September 1, 2024" />
                  </div>


                </div>
              </div>

              <div className='expo-frmc col-lg-6'>
                <strong>Ultrapro Online Expo</strong>
                <div className={chat ? "sign to_mod" : "to_mod"}>


                  <p>Welcome! Please share your information to register for the Ultapro Crypto Expo.</p>
                  <div className='chtbt'>
                    <button type='button' className='btns' onClick={showl}>Register</button>
                    <button type='button' className='btns' onClick={showr}>SignIn</button>
                  </div>

                  <div className="to_fa">
                    <div className="inptbx">
                      <label>Name</label>
                      <input
                        placeholder="Name"
                        name='name'
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleOnlyLetters}
                      />

                      <span id='error' style={{ color: 'red' }}>{errors.name}</span>

                    </div>
                    <div className="inptbx">
                      <label>Email</label>
                      <input
                        type='text'
                        placeholder='Email'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span id='error' style={{ color: 'red' }}>{errors.email}</span>
                    </div>
                    <div className="inptbx">
                      <label>What'sapp Number</label>

                      <input
                        type='text'
                        placeholder='Whatsapp Number'
                        name='whatsAppNumber'
                        value={formData.whatsAppNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={10}
                        onKeyDown={handleOnlyNumbers}
                      />
                      <span id='error' style={{ color: 'red' }}>{errors.whatsAppNumber}</span>



                    </div>
                    <div className="inptbx">
                      <label>Age</label>

                      <input
                        type='text'
                        placeholder='Age'
                        name='age'
                        value={formData.age}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleOnlyNumbers}
                        maxLength={2}
                      />
                      <span id='error' style={{ color: 'red' }}>{errors.age}</span>


                    </div>
                    <div className="inptbx position-relative">
                      <label>ZIP Code</label>

                      <input
                        type='text'
                        placeholder='Enter Your ZIP Code'
                        name='zipCode'
                        value={formData.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={loading}
                        maxLength={6}


                      />
                      {
                        loading ? (


                          <Spinner animation="border" role="status" className='position-absolute top-50' style={{ right: "10px" }}>

                          </Spinner>
                        ) : ("")
                      }


                      <span id='error' style={{ color: 'red' }}>{errors.zipCode}</span>


                    </div>

                    {showAll ? (
                      <>
                        <div className="inptbx">
                          <label>District</label>

                          <input
                            type='text'
                            placeholder='District'
                            name='district'
                            value={formData.district}
                            onChange={handleChange}
                            onBlur={handleBlur}


                          />
                          <span id='error' style={{ color: 'red' }}>{errors.district}</span>


                        </div>
                        <div className="inptbx">
                          <label>State</label>

                          <input
                            type='text'
                            placeholder='State'
                            name='state'
                            value={formData.state}
                            onChange={handleChange}
                            onBlur={handleBlur}


                          />
                          <span id='error' style={{ color: 'red' }}>{errors.state}</span>


                        </div>
                        <div className="inptbx">
                          <label>Country</label>

                          <input
                            type='text'
                            placeholder='Country'
                            name='country'
                            value={formData.country}
                            onChange={handleChange}
                            onBlur={handleBlur}


                          />
                          <span id='error' style={{ color: 'red' }}>{errors.country}</span>


                        </div>
                      </>
                    ) : ("")}
                    <div className="sel_cont">
                      <label>Interested In</label>
                      <select name="interested" value={formData.interested} onChange={handleChange} onBlur={handleBlur}>
                        <option value="">Select your Interest</option>
                        <option value="Both">Both</option>
                        <option value="Crypto_Trading">Crypto Trading</option>
                        <option value="Learning_Blockchain">Learning Blockchain</option>
                      </select>
                      <span id='error' style={{ color: 'red' }}>{errors.interested}</span>
                    </div>
                    <button type="button" className="bton" onClick={handleSubmit}>Let's go</button>
                  </div>

                  <div className="to_fa signin">
                    <div className="inptbx">
                      <label>Email</label>
                      <input
                        type='text'
                        placeholder='Email'
                        name='email'
                        value={ulraproUserLogin.email}
                        onChange={handleUserLoginChange}
                        onBlur={handleUserLoginBlur}
                      />
                      <span id='error' style={{ color: 'red' }}>{errors.email}</span>
                    </div>
                    <div className="inptbx">
                      <label>WhatsApp Number</label>
                      <input
                        type='text'
                        placeholder='WhatsApp Number'
                        name='whatsAppNumber'
                        value={ulraproUserLogin.whatsAppNumber}
                        onChange={handleUserLoginChange}
                        onBlur={handleUserLoginBlur}
                        maxLength={10}
                        onKeyDown={handleOnlyNumbers}
                      />
                      <span id='error' style={{ color: 'red' }}>{errors.whatsAppNumber}</span>
                    </div>
                    <button type="button" className="bton" onClick={signinSubmit}>Submit</button>
                  </div>



                </div>
              </div>

            </div>

          </div>
        </div>
      </LayoutTwo>
    </>
  )
}

export default OnlineExpo