import React from "react";

const SoftwareDownloadTwo = () => {
  return (
    <section
      className="dg__software__area dg__software--2 section-padding--xl"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg/8.jpg"})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="dg__software__inner">
              <h2>Pro network</h2>
              <p>
              Pro Network is designed for UltraPro MainNet, offering limitless scalability, minimal resource consumption, top-tier security, and customizable features. It empowers advanced blockchain applications with robust infrastructure, ensuring efficient operations and enhanced user experiences within the UltraPro ecosystem.

              </p>
        
            </div>
          </div>
          <div className="col-lg-6">
            <div className="dg__software__inner impro">
                
              <img src="images/bg/pronetwork.png" alt="pronetwork" draggable="false"></img>
        
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SoftwareDownloadTwo;
