const environment = 'production'; // Change this to 'staging' or 'production' as needed

let BACKEND_HOST;
let FRONTEND_URL;
let WWW_FRONTEND_URL;
if (environment === 'development') {

    BACKEND_HOST = 'http://localhost:5000';
    FRONTEND_URL = 'http://localhost:3000';
    WWW_FRONTEND_URL = 'http://www.localhost:3000/';

} else if (environment === 'staging') {

    BACKEND_HOST = 'https://backend.beta.ultrapro.info';
    FRONTEND_URL = 'https://staging.ultrapro.info';
    WWW_FRONTEND_URL = 'https://www.staging.ultrapro.info/';

} else if (environment === 'production') {

    BACKEND_HOST = 'https://backend.ultrapro.info';
    FRONTEND_URL = 'https://www.ultrapro.info';
    WWW_FRONTEND_URL = 'https://www.ultrapro.info/';

} else {
    console.log('Environment not set correctly');
}

export const config = {
    BACKEND_HOST,
    VIDEO_UPLOAD: "/ultraAdminRouter/videoLinks",
    CHAT_TEXT_MESSAGE: "/chat/chat-text",
    FRONTEND_URL,
    WWW_FRONTEND_URL,
    CHAT_LIST_ROOM: '/chatRooms/chatRoomList'
};


