import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import ContactMap from "../components/contact-maps/ContactMap";
import Dropdown from 'react-bootstrap/Dropdown';
import { Accordion, Card, Button } from 'react-bootstrap';
import axios from "axios"
import { ApiServices } from "../ApiServices/AllApi";
import { careerSheema } from "../Yup";
import { IoIosArrowDown } from "react-icons/io";
import Loader from 'react-js-loader';
import { toast } from "react-hot-toast";
import { config } from '../config/config'
import { Helmet } from "react-helmet";

const Career = () => {
    const [loading, setLoading] = useState(false);

    const [activeKey, setActiveKey] = useState(null);

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        name: "",
        mobileNumber: "",
        email: "",
        message: '',


        resume: null,

    });

    const [job, setJop] = useState([])
    const [selectedJob, setSelectedJob] = useState('')

    const getJon = async () => {
        try {

            const response = await axios.get(`${config.BACKEND_HOST + ApiServices.USER_CAREER_SERVER}`);
            if (response.status) {
         

                setJop(response.data.data)


            }
        } catch (error) {
            console.error(error)

        }


    }
    useEffect(() => {
        getJon()
    }, [])

    const handleJobChange = (data) => {
        setSelectedJob(data.jobTitle)



    }
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        let sanitizedValue = value;
        let spaceError = "";
        if (name === "resume" && files) {
       
            setFormData({ ...formData, resume: files[0] });
            setErrors({ ...errors, resume: "" }); // Set space error separately for each field

            return;
        }

        if (name === "name") {
            sanitizedValue = value.trimStart();
        }


        if (name !== "name") {
            sanitizedValue = sanitizedValue.replace(/\s/g, "");
        }


        setFormData({ ...formData, [name]: sanitizedValue });
        setErrors({ ...errors, [`${name}SpaceError`]: spaceError }); // Set space error separately for each field

        careerSheema
            .validateAt(name, { [name]: sanitizedValue })
            .then(() => {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: "", [`${name}SpaceError`]: spaceError }));
            })
            .catch((error) => {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message, [`${name}SpaceError`]: spaceError }));
            });
    };

    const handlePost = async (data) => {
        try {
            // Create a new FormData object
            const formDataToSend = new FormData();

            // Append the file (resume) to the formDataToSend if it exists
            if (formData.resume) {
                formDataToSend.append('resume', formData.resume);
            }

            // Define postData object containing email, name, phone, message, and jobTitle
            const postData = {
                email: formData.email,
                name: formData.name,
                phone: formData.mobileNumber,
                message: formData.message,
                jobTitle: data.jobTitle
            };

            // Iterate through the postData object and append each key-value pair to the formDataToSend
            Object.entries(postData).forEach(([key, value]) => {
                formDataToSend.append(key, value);
            });

            // Send the formDataToSend to the server using Axios
            const response = await axios.post(`${config.BACKEND_HOST + ApiServices.USER_GETAPPLICATION_SERVER}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Check if the response is successful
            if (response.status === 200) {
                setLoading(false)
                toast.success(response.data.message, {
                    position: "top-right"
                })
                // If successful, reset form data and selected job
                setFormData({
                    email: "",
                    name: '',
                    phone: '',
                    message: '',
                    resume: null, // Reset resume to null
                });
                selectedJob('');
            } else {
                toast.error(response.data.message, {
                    position: "top-right"
                })
                setLoading(false)

            }
        } catch (error) {
            setLoading(false)

            // Handle any errors that occur during the process
            console.error("Error:", error);
        }
    };



    const handleSubmit = async (data) => {
        // e.preventDefault();

        const emptyFields = {};
        const labelMapping = {
            name: "Name",
            email: "Email",
            message: "Message",
            mobilePhone: "Phone Number",

            resume: "Resume"
        };

        Object.entries(formData).forEach(([key, value]) => {
            if (!value) {
                const label = labelMapping[key] || key;
                emptyFields[key] = `${label} is Required`;
            }
        });


        setErrors({ ...errors, ...emptyFields })


        // If there are empty fields, stop further validation
        if (Object.keys(emptyFields).length > 0) {
            return;
        }


        try {
          
            // Proceed with schema validation
            await careerSheema.validate(formData, { abortEarly: false });


            // Clear errors and proceed with further actions
            setErrors({});
            setLoading(true)
            // console.log("{}{}{");
            handlePost(data);
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((err) => {
                validationErrors[err.path] = err.message;
            });
            setErrors(validationErrors);
        }
    };

    const handleAccordionClick = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    const handleBlur = (e) => {
        const { name } = e.target;

        if (name === 'resume') {
            // No need to validate file inputs onBlur
            return;
        }

        // Validate other fields using Yup schema
        careerSheema
            .validateAt(name, { [name]: formData[name] })
            .then(() => {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            })
            .catch((error) => {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
            });
    };


    const handleOnlyLetters = (e) => {
        if (!/^[a-zA-Z\s.]*$/.test(e.key)) {
            e.preventDefault();
        }
    };


    const [metaTitle, setMetaTitle] = useState();
    const metaTitles = async () => {
        try {
            const getMetaTitle = await axios.get(`${config.BACKEND_HOST}/metaTagRouter/meta-tag?page=career`);
           
            setMetaTitle(getMetaTitle.data.data.title);
        } catch (error) {

        }
    }
    useEffect(() => {
        metaTitles();
    }, [])

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
            </Helmet>
            {loading ? (
                <Loader type="bubble-scale" bgColor={"#1A8F5C"} size={50} />


            ) : (
                <Fragment>

                  


                    <LayoutTwo theme="white">
                        {/* breadcrumb */}
                        <Breadcrumb title="CAREERS" />
                        {/* contact page content */}
                        <div className="career_ins">
                            <div className="container">
                                <h2>Rules and Regulations:</h2>
                                <p>The following details are added to the carrier page on the company website:
                                </p>
                                <ul>
                                    <li>The duration of the training period is almost 3 months after getting the training confirmation of employment letter. The employment period is only calculated after the completion of the training period. The commencement of the probationary period is only calculated from the date of employment. The duration of the probation period is 3 months.    </li>
                                    <li>The trainee cannot be entitled to any stipend/salary/compensation for the period of training. </li>
                                    <li>The entire training cost is spent by the company in its own amount. </li>
                                    <li>If the trainee or employee unfortunately did not complete the training period continuously or after completion of the training without continuing to sail with the company for a period of 1½ years, the company shall always be entitled to recover the said training cost from the trainee or employee as may be under applicable law.
                                    </li>
                                    <li>The trainee or employee should not disclose the confidential information of the company to any person, firm, or corporation. If  disclose the Confidential Information of the company by any trainee/employee upto 12 months from he/she leaving from the company, The Company shall always be entitled to recover the damage cost  from the  trainee/employee as may be  under applicable law. </li>

                                </ul>



                            </div>
                        </div>
                        <section className="conact__area ">

                            <div className="container-fluid">
                                <div className="row align-items-center">

                                    <div className=" col-md-12 col-12 sm__mt--40 md__mt--40 faq">
                                        <div className="dg__contact__wrapper">
                                            {job && job.length > 0 ?
                                                <Accordion activeKey={activeKey} onSelect={handleAccordionClick}>
                                                    {job.map((data, index) => (
                                                        <Card key={index}>
                                                            <Card.Header>
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                                                                    <strong>{data.jobTitle}</strong> <span>{data.jobLocation}</span><span className="bti"><IoIosArrowDown /></span>
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={index.toString()}>
                                                                <Card.Body>
                                                                    <div className='jbd'>
                                                                        <div className='jbd_lft'>
                                                                            <strong>{data.jobTitle}</strong>
                                                                            <p>{data.jobDescriptionDetails}</p>
                                                                        </div>
                                                                        <div className='jbd_rgt'>
                                                                            <strong>Apply Form</strong>
                                                                            <div className="inptbx">
                                                                                <label>Name</label>
                                                                                <input
                                                                                    name='name'
                                                                                    id="name"
                                                                                    placeholder="Name"
                                                                                    value={formData.name}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    onKeyDown={handleOnlyLetters}
                                                                                />
                                                                                <span id='error' style={{ color: 'red' }}>{errors.name}</span>
                                                                            </div>
                                                                            <div className="inptbx">
                                                                                <label>Phone Number</label>
                                                                                <input
                                                                                    type='text'
                                                                                    placeholder='Phone Number'
                                                                                    name='mobileNumber'
                                                                                    value={formData.mobileNumber}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    maxLength={10}
                                                                                />
                                                                                <span id='error' style={{ color: 'red' }}>{errors.mobileNumber}</span>
                                                                            </div>
                                                                            <div className="inptbx">
                                                                                <label>Email</label>
                                                                                <input
                                                                                    type='text'
                                                                                    placeholder='Email'
                                                                                    name='email'
                                                                                    value={formData.email}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                                <span id='error' style={{ color: 'red' }}>{errors.email}</span>
                                                                            </div>
                                                                            <div className="single-contact-form message">
                                                                                <label>Message</label>
                                                                                <textarea
                                                                                    placeholder="Message"
                                                                                    name="message"
                                                                                    value={formData.message}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <span id='error' style={{ color: 'red' }}>{errors.message}</span>
                                                                            </div>
                                                                            <div className="inptbx files">
                                                                                <label>Upload</label>
                                                                                <input
                                                                                    type='file'
                                                                                    placeholder='upload'
                                                                                    name='resume'
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                                <p>Upload Your Resume</p>
                                                                                {formData.resume && <span>Selected File: {formData.resume.name}</span>}
                                                                                <span id='error' style={{ color: 'red' }}>{errors.resume}</span>
                                                                            </div>
                                                                            <button type='button' className='bt_cont' onClick={() => handleSubmit(data)}>Apply</button>
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    ))}
                                                </Accordion>
                                                :
                                                <p className="none">No record found</p>
                                            }

                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </LayoutTwo>

                   
                </Fragment>
            )}
        </>
    );
};

export default Career;