import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";
import { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
import { Helmet } from "react-helmet";
import { config } from "../../config/config";
const AboutContent = () => {
  const [metaTitle, setMetaTitle] = useState();
  const metaTitles = async () => {
    try {
      const getMetaTitle = await axios.get(`${config.BACKEND_HOST}/metaTagRouter/meta-tag?page=about`);

      setMetaTitle(getMetaTitle.data.data.title);
    } catch (error) {

    }
  }

  useEffect(() => {
    AOS.init({
      duration: 3000, // animation duration in milliseconds
    });
    metaTitles();
  }, []);
  return (
    <>
      <div className="md_det">
        <Helmet>
          <title>{metaTitle}</title>
        </Helmet>
        <div className="container">
          <div className="mdcont row">
            <div className="md_con col-lg-6">
              <h2>Nagarajan Narayanasamy</h2>
              <strong>Founder of Ultrapro Blockchain</strong>
              <p>Ultrapro Blockchain, founded in 2021, is committed to decentralizing the internet through advanced blockchain technology and DApps. In 2023, we launched our official website, and by 2024, we incorporated our office in India, reinforcing our global expansion.
              </p>
              <p>Guided by our founder, Mr. Nagarajan Narayanasamy, we continue to innovate and lead in the blockchain space with secure and scalable solutions.</p>
              <h3>About Us</h3>
              <p>Ultrapro Blockchain is a leader in pioneering the decentralization of the internet through state-of-the-art blockchain technology and decentralized applications (DApps).
              </p>
              <p>We take a comprehensive and forward-thinking approach to blockchain technology. Our team of experts is dedicated to researching and developing cutting-edge applications that address real-world challenges. By leveraging our deep industry knowledge and technical expertise, we create secure, scalable, and user-friendly solutions.
              </p>
              <p>Guiding Ultrapro Blockchain is our esteemed founder, Mr.Nagarajan Narayanasamy, an accomplished MBA graduate from the prestigious Indian Institute of Management (IIM). He brings a wealth of expertise and strategic vision to the company. His leadership has been pivotal in positioning Ultrapro Blockchain at the forefront of the industry. With a profound passion for technology and innovation, our founder is dedicated to pushing the boundaries of what is possible in the blockchain.  </p>

              <h3>UPRO Launch and Milestones</h3>
              <p>At Ultrapro Blockchain, we are excited to share the progress we’ve made over the past year. We have been committed to advancing the decentralization of the internet with our native coin, UPRO.</p>
              <p>In this past year, we have distributed nearly 2 million UPROs through airdrops. Along with that, We have honored the UPRO ranging from 1 cent to 10 cents. </p>
              <p>This initiative was designed to foster community engagement and promote the adoption of UPRO within our ecosystem.</p>
              <p>We are thrilled to announce that UPRO is officially launching in October 2024. This milestone marks a significant step forward in our mission to support and enhance decentralized networks. As we prepare for this exciting launch, we remain dedicated to providing innovative solutions and contributing to the future of blockchain technology.</p>
            </div>
            <div className="md_im col-lg-6">
              <img src="images/about/md.jpg" alt="ultrapro blockchain" draggable="false"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="about__dgtaka about--2 section-padding--xl">
        <div className="container">
          <div className="row align-items-center dgtaka">
            <div className="col-lg-7 col-12 col-sm-12 col-md-12">
              <div className="dg__secure__inner">
                {/* section title */}
                <SectionTitleFour title="Key Achievements" />
                <ul>
                  <li>Native Coin (UPRO): UltraPro Blockchain's native coin, UPRO, boasts a total supply of 109 million. Designed for efficiency and scalability, UPRO serves as the backbone of our ecosystem.
                  </li>
                  <li>Layer One Blockchain: Our Layer One blockchain technology ensures low gas fees for transactions, making it cost-effective for users and developers alike.
                  </li>
                  <li>High Transaction Throughput: UltraPro Blockchain supports up to 20,000 transactions per second, ensuring fast and reliable transaction processing.
                  </li>
                  <li>Stablecoin (PRO 20 USDT): We have introduced our own stablecoin, PRO 20 USDT, providing stability and reliability for digital transactions.
                  </li>
                  <li>Decentralized Finance (DeFi) Solutions: Our DeFi payment gateway offers seamless and secure payment processing, revolutionizing the way transactions are conducted.                </li>
                  <li>NFT Platform: UltraPro Blockchain's NFT platform empowers creators and collectors with a secure and versatile marketplace for digital assets.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-12 col-sm-12 col-md-12 sm__mt--40 md__mt--40">
              <div className="dg__secure__thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/6.png"}
                  alt="secure images" draggable="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md_det">
        <div className="container">
          <div className="mdcont row mdro">
            <div className="md_con col-lg-6">
              <h2>Legal Establishment of Ultrapro Blockchain </h2>
              <p>As we mentioned above, Founded in 2021, Ultrapro Blockchain has swiftly emerged as a leader in blockchain technology and decentralized applications (DApps).
                In 2024, we took a major step in our journey by incorporating in India, solidifying our presence within a key global market. This incorporation marks our dedication to transparency, security, and compliance with international standards.
                With a robust infrastructure and a forward-thinking approach, Ultrapro Blockchain is at the forefront of transforming the digital landscape. We continue to push the boundaries of blockchain technology, providing secure and efficient solutions that support the future of decentralized networks worldwide.
              </p>
            </div>
            <div className="md_im col-lg-6">
              <img src="images/events/certificate.png" alt="ultrapro blockchain" draggable="false"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="abt_con">
        <div className="container">
          <div className="missvisi row">
            <div className="miss col-lg-5 col-md-12" data-aos="fade-right">
              <h2>Our Mission</h2>
              <p>Our mission is to accelerate the decentralization of the internet, empowering individuals and businesses with secure, transparent, and efficient blockchain solutions. We aim to create a decentralized ecosystem that fosters innovation, inclusivity, and financial freedom.
              </p>
            </div>
            <div className="visi col-lg-5 col-md-12" data-aos="fade-left">
              <h2>Our Vision</h2>
              <p>We envision a decentralized future where blockchain technology transforms industries, enhances transparency, and fosters global collaboration. UltraPro Blockchain is committed to driving this transformation by providing robust, scalable, and user-friendly solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutContent;
