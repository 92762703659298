import React, { useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { EmailSend } from "../../Yup";
import emailjs from 'emailjs-com';
import toast from "react-hot-toast";
import { config } from "../../config/config";
import { ApiServices } from "../../ApiServices/AllApi";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Link, useHistory } from 'react-router-dom';

const Navigation = () => {
  
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsAppNumber: '',
    age: "",
    district: "",
    interested: ""
  });

  useEffect(() => {
    emailjs.init("uhuWsxq6yK7Z6XCJs");
  }, []);

  const [show, showNext] = useState(false);
  const [thank, showThank] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      history.push('/live-online-expo');
    } else {
      history.push('/online-expo');
    }
  };




  const modClick = () => {
    showNext(true)
  }
  const modbClose = () => {
    showNext(false)
    // showThank(true)
  }
  const modb = () => {

    showThank(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;
    let spaceError = "";


    if (name === "name") {
      sanitizedValue = value.trimStart();
    }


    if (name !== "name") {
      sanitizedValue = sanitizedValue.replace(/\s/g, "");
    }

    setFormData({ ...formData, [name]: sanitizedValue });
    setErrors({ ...errors, [`${name}SpaceError`]: spaceError }); // Set space error separately for each field

    EmailSend
      .validateAt(name, { [name]: sanitizedValue })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "", [`${name}SpaceError`]: spaceError }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message, [`${name}SpaceError`]: spaceError }));
      });
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    if (name === 'resume') {
      // No need to validate file inputs onBlur
      return;
    }

    // Validate other fields using Yup schema
    EmailSend
      .validateAt(name, { [name]: formData[name] })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      });
  };

  const handleOnlyLetters = (e) => {
    if (!/^[a-zA-Z\s.]*$/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleOnlyNumbers = (e) => {
    const validKeys = /^[0-9\s.]$/;
    if (!validKeys.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };


  const handleSubmit = async (data) => {
    // e.preventDefault();

    const emptyFields = {};
    const labelMapping = {
      name: "Name",
      email: "Email",
      age: "Age",
      whatsAppNumber: "What'sApp Number",
      district: "District",
      interested: "Interested"

    };

    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });


    setErrors({ ...errors, ...emptyFields })


    // If there are empty fields, stop further validation
    if (Object.keys(emptyFields).length > 0) {
      return;
    }


    try {
 
      // Proceed with schema validation
      await EmailSend.validate(formData, { abortEarly: false });
      const response = await axios.post(`${config.BACKEND_HOST + ApiServices.ULTRA_PRO_EXPO}`, formData);

      if (response.data.status) {
        toast.success('successfully sent!', {
          position: "top-right"
        })
        showNext(false)
        showThank(true)
        setFormData({
          name: "",
          email: "",
          whatsAppNumber: '',
          age: "",
          district: "",
          interested: ""
        });
      } else {
        toast.error('Not submitted!', {
          position: "top-right"
        })
      }
      setErrors({});


    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const emptyFields = {};
  //   const labelMapping = {
  //     name: "Name",
  //     email: "Email",
  //     age: "Age",
  //     whatsAppNumber: "WhatsApp Number",
  //   };

  //   Object.entries(formData).forEach(([key, value]) => {
  //     if (!value) {
  //       const label = labelMapping[key] || key;
  //       emptyFields[key] = `${label} is Required`;
  //     }
  //   });

  //   setErrors({ ...errors, ...emptyFields });

  //   if (Object.keys(emptyFields).length > 0) {
  //     return;
  //   }

  //   try {
  //     // EmailJS configuration
  //     const serviceID = 'service_1nl640t';
  //     const templateID = 'template_lieo5za';
  //     const userID = 'uhuWsxq6yK7Z6XCJs';

  //     const templateParams = {
  //       to_email: 'nandhu03tmg@gmail.com',
  //       from_name: formData.name,
  //       message: `Name: ${formData.name}\nEmail: ${formData.email}\nAge: ${formData.age}\nWhatsApp Number: ${formData.whatsAppNumber}`,
  //     };

  //     await emailjs.send(serviceID, templateID, templateParams, userID);
  //     console.log('Email successfully sent!');
  //     toast.success('successfully sent!', {
  //       position: "top-right"
  //     })

  //     // Clear form and errors
  //     setFormData({
  //       name: '',
  //       email: '',
  //       age: '',
  //       whatsAppNumber: '',
  //     });
  //     setErrors({});
  //     showNext(false)
  //     showThank(true)

  //   } catch (error) {
  //     toast.error("Try Again Latter", {
  //       position: "top-right"
  //     })
  //     console.error('Failed to send email. Error:', error);
  //   }
  // };
  const location = useLocation();
  useEffect(() => {
    // Check the path and decide whether to open the modal
    if (location.pathname === '/live-online-expo') {
      showNext(true)
    } else {
      showNext(false)
    }
  }, [location]);


  // Function to get initial active index based on current path
  function getInitialActiveIndex(pathname) {
    switch (pathname) {
      case process.env.PUBLIC_URL + "/":
        return 0;
      case process.env.PUBLIC_URL + "/about-us":
        return 1;
      case process.env.PUBLIC_URL + "/blog":
        return 2;
      case process.env.PUBLIC_URL + "/enhanced-enterprises":
        return 3;
      default:
        return -1; // No active item by default
    }
  }
  const [active, setActive] = useState(getInitialActiveIndex(location.pathname)); // Initial active state, defaulting to the first item

  const activecl = (index) => {
    setActive(index); // Function to set the active index on click
  }
  return (
    <>
      {/* <Modal
        show={show}
        className="tradmod"
        backdrop="static"
        centered="bool"
        size="lg"
      >
        <Modal.Header closeButton onClick={modbClose}>
          <strong>Ultrapro Online Expo</strong>
        </Modal.Header>
        <Modal.Body>
          <div className='to_mod'>
            <p>Welcome! Please share your information to register for the Ultapro Crypto Expo.</p>
            <div className="to_fa">
              <div className="inptbx">
                <label>Name</label>
                <input
                  placeholder="Name"
                  name='name'
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleOnlyLetters}
                />

                <span id='error' style={{ color: 'red' }}>{errors.name}</span>

              </div>
              <div className="inptbx">
                <label>Email</label>
                <input
                  type='text'
                  placeholder='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span id='error' style={{ color: 'red' }}>{errors.email}</span>
              </div>
              <div className="inptbx">
                <label>What'sapp Number</label>

                <input
                  type='text'
                  placeholder='Whatsapp Number'
                  name='whatsAppNumber'
                  value={formData.whatsAppNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={10}
                  onKeyDown={handleOnlyNumbers}
                />
                <span id='error' style={{ color: 'red' }}>{errors.whatsAppNumber}</span>



              </div>
              <div className="inptbx">
                <label>Age</label>

                <input
                  type='text'
                  placeholder='Age'
                  name='age'
                  value={formData.age}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleOnlyNumbers}
                  maxLength={2}
                />
                <span id='error' style={{ color: 'red' }}>{errors.age}</span>


              </div>
              <div className="inptbx">
                <label>District</label>

                <input
                  type='text'
                  placeholder='District'
                  name='district'
                  value={formData.district}
                  onChange={handleChange}
                  onBlur={handleBlur}


                />
                <span id='error' style={{ color: 'red' }}>{errors.district}</span>


              </div>
              <div className="sel_cont">
                <label>Interested In</label>
                <select name="interested" value={formData.interested} onChange={handleChange} onBlur={handleBlur}>
                  <option value="">Select your Interest</option>
                  <option value="Crypto_Training">Crypto Training</option>
                  <option value="Learning_Blockchain">Learning Blockchain</option>
                </select>
                <span id='error' style={{ color: 'red' }}>{errors.interested}</span>
              </div>
              <button type="button" className="bton" onClick={handleSubmit}>SUBMIT</button>
            </div>

          </div>
        </Modal.Body>

      </Modal> */}
   
      <nav className="mainmenu__nav">
        <ul className="mainmenu">
          <li className="drop">
            <Link to={process.env.PUBLIC_URL + "/"}   className={`${active === 0 ? 'active' : ''}`}
        onClick={() => activecl(0)}>Home</Link>
          </li>
          <li className="drop">
            <a href="javascript:void(0);" >
              Ecosystem <IoIosArrowDown />
            </a>
            <ul className="dropdown">
            <li>
                <a href="https://ultraproscan.io/" target="_blank"> Explorer </a>
              </li>
              <li>
                <a href="https://upronft.com/" target="_blank">UPRO NFT</a>
              </li>
            
              <li>
                <a href="https://www.amazon.com/dp/B0CLKVQX1Q" target="_blank">Canopus GTX wallet </a>
              </li>
              <li>
                <a href="https://upropayments.com/" target="_blank">UPRO Payments</a>
              </li>
              <li>
                <a href="https://eenaswap.finance/" target="_blank">DeFi</a>
              </li>
              <li>
                <a href="https://canopro.com/stake" target="_blank">Staking</a>
              </li>
              
             
              <li>
                <a href="https://www.ultraproex.com/" target="_blank">Ultrapro Exchange</a>
              </li>
         
              <li>
                <a href="https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers" target="_blank">Pro-20 USDT </a>
              </li>
              
             
          
            </ul>
          </li>
          <li className="drop">
            <Link to={process.env.PUBLIC_URL + "/about-us"} className={`${active === 1 ? 'active' : ''}`}
        onClick={() => activecl(1)}>
              About us
            </Link>
            {/* <ul className="dropdown">
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog"}>Blog Page</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-right-sidebar"}>
                Blog Right Sidebar
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog"}>
                Blog Details
              </Link>
            </li>
          </ul> */}
          </li>
          <li><Link to={process.env.PUBLIC_URL + "/blog"} className={`${active === 2 ? 'active' : ''}`}
        onClick={() => activecl(2)}>
              Blog
            </Link></li>
          <li className="drop">
            <a href="javascript:void(0);" >
              Token <IoIosArrowDown />
            </a>
            <ul className="dropdown">
              <li>
                <a href="https://ultraproscan.io/token/0x1F5712B75B01FF9bD337811b56E3c9f1257CEa86/token-transfers" target="_blank"> UBIT</a>
              </li>
              <li>
                <a href="https://ultraproscan.io/token/0x229268FEeaC6C6D620E09F09825a6f3CdA9ec17a/token-transfers" target="_blank"> Honey</a>
              </li>
              <li>
                <a href="https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers" target="_blank">USDT</a>
              </li>
              <li>
                <a href="https://ultraproscan.io/token/0x229268FEeaC6C6D620E09F09825a6f3CdA9ec17a/token-transfers" target="_blank">BUSD</a>
              </li>
              <li>
                <a href="https://ultraproscan.io/token/0xf29f34DF858d971Fc148d2db40647769676606dA/token-transfers" target="_blank">UPRONFT</a>
              </li>
            </ul>
          </li>
          <li className="drop">
          <a href="javascript:void(0);" onClick={handleClick} >
            Ultrapro's Expo
          </a>
        </li>
            {/* <ul className="dropdown">
            <li>
              <Link to={process.env.PUBLIC_URL + "/about"}></Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/about-bitcoin"}>
                About Bitcoin
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/merchants"}>Merchants</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/team"}>Team Page</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/wallet"}>Wallet Page</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                Login / Register
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
            </li>
          </ul> */}
     
          <li>
            <Link to={process.env.PUBLIC_URL + "/enhanced-enterprises"} className={`${active === 3 ? 'active' : ''}`}
        onClick={() => activecl(3)}>Enhanced-Enterprises</Link>
          </li>

        </ul>
      </nav>
    </>
  );
};

export default Navigation;
