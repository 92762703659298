import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiServices } from "../../ApiServices/AllApi";
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router-dom';
import { config } from '../../config/config';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from "react-helmet";
import BlogPostContent from "./BlogPostContent";
const BlogContent = () => {
  const history = useHistory();
  const [blogData, setBlogData] = useState([])
  const [announcementData, setAnnouncementData] = useState([])
  const [newsData, setNewsData] = useState([])
  const combinedData = [...blogData, ...announcementData, ...newsData];
  const [currentPage, setCurrentPage] = useState(0);
  const [metaTitle, setMetaTitle] = useState();


  const itemsPerPage = 12; // Number of items to display per page

  let pageCount = 0;
  let pageAnnounceCount = 0;
  let pageNewsCount = 0;


  if (blogData && blogData.length > 0) {
    pageCount = Math.ceil(blogData.length / itemsPerPage);
  }
  if (announcementData && announcementData.length > 0) {
    pageAnnounceCount = Math.ceil(announcementData.length / itemsPerPage);
  }
  if (newsData && newsData.length > 0) {
    pageNewsCount = Math.ceil(newsData.length / itemsPerPage);
  }




  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const fetchData = async () => {
    try {
      const blogResponse = await axios.get(`${config.BACKEND_HOST + ApiServices.ULTRA_BLOG_LIST}`);
      setBlogData(blogResponse.data.data);

      const announcementResponse = await axios.get(`${config.BACKEND_HOST + ApiServices.ULTRA_ANNOUNCEMENT_LIST}`);
      setAnnouncementData(announcementResponse.data.data);

      const newsResponse = await axios.get(`${config.BACKEND_HOST + ApiServices.ULTRA_NEWS_LIST}`);
      setNewsData(newsResponse.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  let displayedData = []

  if (announcementData && announcementData.length > 0) {
    displayedData = announcementData.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );
  }


  let displayedNews = []

  if (newsData && newsData.length > 0) {
    displayedNews = newsData.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );
  }

  const handleSelect = (data) => {
    history.push('/blog', { data });

  }
  const utcToNormalTime = (utcString) => {
    const date = new Date(utcString);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
    return { date: formattedDate, time: formattedTime };
  };

  const metaTitles = async () => {
    try {
      const getMetaTitle = await axios.get(`${config.BACKEND_HOST}/metaTagRouter/meta-tag?page=blog`);

      setMetaTitle(getMetaTitle.data.data.title);
    } catch (error) {

    }
  }

  const saveBlogId = (blogId) => {

    // Save the blogId to localStorage
    localStorage.setItem('blogId', blogId);

  };

  // const itemsPerPage = 10;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);

  const handleLoadMore = () => {
    setVisibleItems((prev) => prev + itemsPerPage);
  };

  const handleLoadLess = () => {
    setVisibleItems((prev) => (prev - itemsPerPage < itemsPerPage ? itemsPerPage : prev - itemsPerPage));
  };

  

  useEffect(() => {
    metaTitles();
    // localStorage.removeItem('blogId');
  }, [])

  return (
    <div className="dg__blog__area bg--white pt--110 pb--140">
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <div className="container">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Learn</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Announcement</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="false">News</button>
          </li>

        </ul>


        <div class="tab-content">
          <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div className="bl_cont">
              {blogData.length > 0 ? (
                <>
                  <div className="row">
                    <div className='hom'>
                      {blogData.slice(0, visibleItems).map((data, index) => (
                        <div className='homs' key={index}>
                          <a
                            href={`${process.env.PUBLIC_URL}/blog${ApiServices.SUB_LEARN_ROUTE}/${data.title.toLowerCase().replace(/\s+/g, '-')}`}
                            onClick={() => saveBlogId(data._id)}>
                            <div>
                              <img src={data.image} alt={data.title} draggable="false" />
                              <div className='hombl'>
                                <strong>{data.title}</strong>
                                <ul>
                                  <li>Last Updated |</li>
                                  <li>{utcToNormalTime(data.createdAt).date}</li>
                                </ul>
                                <a href={`${process.env.PUBLIC_URL}/blog/${data.title}`}>Learn More</a>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row mt--40">
                    <div className="col-lg-12">
                      {visibleItems < blogData.length && (
                        <button onClick={handleLoadMore} className="btn btn-primary">Load More</button>
                      )}
                      {visibleItems > itemsPerPage && (
                        <button onClick={handleLoadLess} className="btn btn-secondary">Load Less</button>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <p className="nodata">No Record Found</p>
              )}
            </div>
          </div>
          <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div className="bl_cont">
              {announcementData && announcementData.length > 0 ?
                <>
                  <div className="row">

                    <div className='hom'>

                      {announcementData.slice(0, visibleItems).map((data, index) => (
                        <div className='homs' key={index}>
                          <a
                            href={`${process.env.PUBLIC_URL}/blog${ApiServices.SUB_ANNOUNCEMENT_ROUTE}/${data.title.toLowerCase().replace(/\s+/g, '-')}`}
                            onClick={() => saveBlogId(data._id)}>
                            <div>
                              <img src={data.image} alt={data.title} draggable="false" />
                              <div className='hombl'>
                                <strong>{data.title}</strong>
                                <ul>
                                  <li>Last Updated |</li>
                                  <li>{utcToNormalTime(data.createdAt).date}</li>
                                </ul>
                                Learn More
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>

                  </div>

                  <div className="row mt--40">
                    <div className="col-lg-12">
                      {visibleItems < announcementData.length && (
                        <button onClick={handleLoadMore} className="btn btn-primary">Load More</button>
                      )}
                      {visibleItems > itemsPerPage && (
                        <button onClick={handleLoadLess} className="btn btn-secondary">Load Less</button>
                      )}
                    </div>
                  </div>
                </>
                :
                <p className="nodata">No Record Found</p>
              }
            </div>
          </div>
          <div class="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">
            <div className="bl_cont">
              {newsData && newsData.length > 0 ?
                <>

                  <div className="row">
                    <div className='hom'>
                    {newsData.slice(0, visibleItems).map((data, index) => (
                     
                        <div className='homs' key={index}>
                          <a
                            href={`${process.env.PUBLIC_URL}/blog${ApiServices.SUB_NEWS_ROUTE}/${data.title.toLowerCase().replace(/\s+/g, '-')}`}
                            onClick={() => saveBlogId(data._id)}>
                            <div>
                              <img src={data.image} alt={data.title} draggable="false" />
                              <div className='hombl'>
                                <strong>{data.title}</strong>
                                <ul>
                                  <li>Last Updated |</li>
                                  <li>{utcToNormalTime(data.createdAt).date}</li>
                                </ul>

                                Learn More

                              </div>
                            </div>
                          </a>
                        </div>

                      ))}
                    </div>
                  </div>


                  <div className="row mt--40">
                    <div className="col-lg-12">
                      {visibleItems < newsData.length && (
                        <button onClick={handleLoadMore} className="btn btn-primary">Load More</button>
                      )}
                      {visibleItems > itemsPerPage && (
                        <button onClick={handleLoadLess} className="btn btn-secondary">Load Less</button>
                      )}
                    </div>
                  </div>
                </>
                :
                <p className="nodata">No Record Found</p>
              }
            </div>
          </div>

        </div>


      </div>
    </div>
  );
};

export default BlogContent;


// import React, { useState } from 'react';

// const PincodeLookup = () => {
//   const [pincode, setPincode] = useState('');
//   const [pincodeDetails, setPincodeDetails] = useState(null);
//   const [error, setError] = useState('');

//   const fetchPincodeDetails = async () => {
//     try {
//       const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${pincode}&key=a04c1d8f337d4ea6a37d8bf0e0c3d5df`);
//       if (!response.ok) {
//         throw new Error('Pincode not found.');
//       }
//       const data = await response.json();
//       setPincodeDetails(response.data); // Assuming the API returns an array with the first element as the pincode details
//       setError('');
//     } catch (error) {
//       setError(error.message || 'Error fetching pincode details.');
//       setPincodeDetails(null);
//     }
//   };

//   const handleSearch = () => {
//     if (pincode.trim() === '') {
//       setError('Please enter a pincode.');
//       return;
//     }
//     fetchPincodeDetails();
//   };

//   return (
//     <div>
//       <h2>Indian Pincode Lookup</h2>
//       <input
//         type="text"
//         value={pincode}
//         onChange={(e) => setPincode(e.target.value)}
//         placeholder="Enter Pincode"
//       />
//       <button onClick={handleSearch}>Search</button>

//       {error && <p style={{ color: 'red' }}>{error}</p>}

//       {pincodeDetails && (
//         <div>
//           <h3>Pincode Details</h3>
//           <p>Pincode: {pincodeDetails.pincode}</p>
//           <p>Area: {pincodeDetails.officeName}</p>
//           <p>District: {pincodeDetails.district}</p>
//           <p>State: {pincodeDetails.state}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PincodeLookup;