import React, { Fragment } from 'react'
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import Slider from "react-slick";

const UltraproTrust = () => {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        
        slidesToShow: 1,
        slidesToScroll: 1,
      };

  return (
 
    <>
    <Fragment>
      
      <LayoutTwo theme="white">
        <Breadcrumb title="Ultrapro Trust" />
        <div className='ultr_tst'>
            <div className='container'>
            <h2>Serving in Our Community's Future!</h2>
             <p>Welcome to Ultrapro Trust, where social responsibility meets meaningful action. At Ultrapro Blockchain, we are committed to making a positive impact in our communities through various initiatives:   
             </p>
             <div className='ultr_trust row'>
                <div className='ultr_lft col-md-6'>
                    <div className='ultr'>
                        <img src='images/bg/food.jpg' alt='food-distribution'  draggable="false"></img>
                        <div className='ul_tr'>
                        <span><img src='images/bg/tree.jpg' alt="tree-plant"  draggable="false"></img></span>
                        <span><img src='images/bg/hand.jpg' alt='hand'  draggable="false"></img></span>
                        </div>
                    </div>
                </div>
                <div className='ultr_rgt col-md-6'>
                    <h3>Activities We Lead:</h3>
                    <div className="bns">
                    <Slider {...settings}>
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h3>Food Donation </h3>
                        <p>We actively organize and participate in food donation drives to alleviate hunger and support local food banks. By partnering with charitable organizations, we ensure that nutritious meals reach individuals and families facing food insecurity.</p>
                        
                        </div>
                        
                    </div>
                    <div className="bt_ecocont">
                        <div className="bt-lft">
                        <h3>Old Age Homes</h3>
                        <p>Our dedication to enhancing the lives of senior citizens is evident through our partnerships with elderly care facilities. We engage in activities that bring companionship, joy, and support to residents, fostering a nurturing environment where seniors can thrive. </p>
                        </div>
                        
                    </div>

                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h3>Support for the Handicapped</h3>
                        <p>At Ultrapro Blockchain, we believe in inclusivity and empowerment for individuals with disabilities. Through collaborative efforts with disability-focused organizations, we promote education, rehabilitation, and vocational training opportunities. We aim to break barriers and create pathways to independence and success.   </p>

                        </div>
                    
                    </div>
                    
                    <div className="bt_ecocont">
                    <div className="bt-lft">
                        <h3>Tree Sapling Initiatives</h3>
                        <p>Environmental sustainability is a cornerstone of our values. Through tree sapling initiatives, we actively contribute to greening our communities. By organizing tree-planting events and partnering with environmental groups, we help improve air quality, mitigate climate change, and enhance biodiversity for a healthier planet.
                        </p>
                        
                        </div>
                        
                    </div>
                   </Slider>
                 </div>
                </div>
             </div>
             </div>
        </div>
        <div className='ultra_ust'>
            <div className='container'>
        <div className='ultr_trust ulrrr row align-items-center'>
                <div className='ultr_lft col-md-6'>
                <div className='ultr'>
                        <img src='images/bg/feel.jpg' alt='feel'></img>
                        <div className='ul_tr'>
                        <span><img src='images/bg/waters.jpg' alt='waters'></img></span>
                        <span><img src='images/bg/hands.jpg' alt='hands'></img></span>
                        </div>
                    </div>
                </div>
                <div className='ultr_rgt col-md-6'>
                    <h3>Benefits of serving for communities: </h3>
                    <div className="bns">
        <Slider {...settings}>
        <div className="bt_ecocont">
          <div className="bt-lft">
               <h3>Helping Others: </h3>
               <p>Serving social services helps make the community better by providing food, care for seniors, and support for people with disabilities.
               </p>
               
               </div>
            
           </div>
           <div className="bt_ecocont">
            <div className="bt-lft">
               <h3>Connecting with Others:</h3>
               <p>It brings people together and helps them support each other.
               </p>
               </div>
             
           </div>

           <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>Feeling Good:</h3>
               <p>At Ultrapro Blockchain, we believe in inclusivity and empowerment for individuals with disabilities. Through collaborative efforts with disability-focused organizations, we promote education, rehabilitation, and vocational training opportunities. We aim to break barriers and create pathways to independence and success.   </p>

              </div>
          
           </div>
         
           <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>Fairness:</h3>
               <p>It makes sure everyone gets the help they need, no matter their situation. </p>
              
               </div>
               
           </div>
           <div className="bt_ecocont">
           <div className="bt-lft">
               <h3>Stronger Communities:</h3>
               <p>It helps communities stay strong and able to handle challenges better in the future.               </p>
              
               </div>
               
           </div>
      
    </Slider>
                  </div>
                </div>
             </div>
             </div>
             </div>
             <div className='ultra_last'>
                <div className='container'>
                    <h2>How You Can Contribute?</h2>
                    <p> Your involvement is crucial in making our initiatives impactful. Whether through volunteering your time, making donations, or spreading awareness, your support strengthens our efforts to build resilient and compassionate communities together.</p>
                    <p>Engage with Ultrapro Trust and create change!</p>
                </div>
             </div>
      </LayoutTwo>
    
    </Fragment>
    </>
  )
}

export default UltraproTrust