import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import "./Expo.css";
import { config } from "../config/config";
import axios from "axios";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import io from "socket.io-client";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom"


const socket = io(config.BACKEND_HOST.replace(/\/$/, "")); // Connect to your server


const Expo = () => {
  const history = useHistory();
  const lastMessageRef = useRef(null);
  const userId = localStorage.getItem("_id");
  const name = localStorage.getItem("name");
  const [uploadedVideo, setUploadedVideo] = useState([]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState(false);
  const [userName, setUserName] = useState(userId);
  const [textMessage, setTextMessage] = useState([]);


  useEffect(() => {
    const fetchChatMessages = () => {
      socket.emit("get-chat-list");
    };

    socket.on("chat-list-response", (data) => {

      if (data.status) {
        setTextMessage(data.data);
      } else {
        setTextMessage([]);
      }
    });

    socket.on("chat-message", (data) => {
      setTextMessage((prevMessages) => [...prevMessages, data]);
    });

    const intervalId = setInterval(fetchChatMessages, 1000);

    fetchChatMessages();

    return () => {
      clearInterval(intervalId);
      socket.disconnect();
    };
  }, []);

  const showChat = () => {
    setChat(!chat);
    if (!chat) {
      socket.emit("get-chat-list");
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${config.BACKEND_HOST}${config.VIDEO_UPLOAD}`);
        setUploadedVideo(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();

    // Cleanup function to disconnect socket
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on("chat-message", (data) => {
      setMessages((messages) => [
        ...messages,
        { name: data.name, message: data.message },
      ]);
    });

    socket.on("user-connected", (name) => {
      setMessages((messages) => [
        ...messages,
        { name: "System", message: `${name} connected` },
      ]);
    });

    socket.on("user-disconnected", (name) => {
      setMessages((messages) => [
        ...messages,
        { name: "System", message: `${name} disconnected` },
      ]);
    });


    return () => {
      socket.off("chat-message");
      socket.off("user-connected");
      socket.off("user-disconnected");
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userName.trim()) {
        socket.emit("new-user", userName);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [userName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userName.trim()) {
      socket.emit("new-user", userName);
    }
    if (message.trim()) {
      const response = await axios.post(`${config.BACKEND_HOST}${config.CHAT_LIST_ROOM}`);

      const chatRoomNameList = response.data.data.name;
      socket.emit("send-chat-message", {
        message,
        room: chatRoomNameList,
        name,
      });
      setMessage("");
    }
  };

  const getEmbedLink = (link) => {
    if (!link) return "";
    const youtubeMatch = link.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    if (youtubeMatch) {
      return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    }
    return link;
  };


  const [metaTitle, setMetaTitle] = useState();
  const metaTitles = async () => {
    try {
      const getMetaTitle = await axios.get(`${config.BACKEND_HOST}/metaTagRouter/meta-tag?page=expo-login`);

      setMetaTitle(getMetaTitle.data.data.title);
    } catch (error) {

    }
  }




  useEffect(() => {
    const expirationTime = localStorage.getItem('tokenExpiration');
    if (expirationTime && new Date().getTime() >= parseInt(expirationTime, 10)) {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
      history.push('/online-expo');
    }
    metaTitles();

  }, [])

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [textMessage]);

  return (
    <>
      

      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>

      <LayoutTwo theme="white">
        <Breadcrumb title="LIVE EXPO" />
        <div className="stre_vid">
          <div className="container">
            <div className='stream-chat row'>

              <div className="vido col-md-8">
                <div className="container">
                  {uploadedVideo.length > 0 ? (
                    <div className="vidos_liv">
                      {uploadedVideo.map((video, index) => (
                        <iframe
                          key={index}
                          width="800"
                          height="450"
                          src={getEmbedLink(video.videoUrl)}
                          // src='http://localhost:3002/liveExpo?roomID=J1LUz&role=Audience'
                          title={`video-${index}`}
                          frameBorder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      ))}
                    </div>
                  ) : (
                    <p>No videos uploaded yet.</p>
                  )}
                </div>
              </div>

              <div className="chatbx col-md-4">
                <div className="container">
                  <button type="button" className="btns" onClick={showChat}>
                    Chat with Members
                  </button>
                  <div className={chat ? "video_chat" : "close"}>
                    <strong>
                      <span>
                        <img src="images/bg/chat.svg" alt="chatbox"  draggable="false" />
                      </span>
                      Chat Now
                    </strong>
                    <div className="videoc">
                      {textMessage.length > 0 ? (
                        <div className="msg_hed">
                          {textMessage.map((msg, index) => (
                            <div
                              key={index}
                              className={msg.name === name ? "message right" : "message left"}
                              ref={index === textMessage.length - 1 ? lastMessageRef : null}
                            >
                              <p className="message-header">{msg.name}</p>
                              <span>{msg.message}</span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="no-messages">No messages found.</p>
                      )}
                      <div className="inptbx">
                        <input
                          type="text"
                          id="message-input"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Type your message..."
                        />
                        <input
                          type="hidden"
                          value={userId}
                          onChange={(e) => setUserName(e.target.value)}
                          placeholder="Enter your name"
                        />
                        <span className="send" onClick={handleSubmit}>
                          <img src="images/bg/send.svg" alt="sendim"  draggable="false" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutTwo>
    </>
  );
};

export default Expo;
