import React from 'react'
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import { useState, useEffect } from "react";
import { config } from '../config/config'
import { Helmet } from "react-helmet";
import axios from "axios";

const Privacypolicy = () => {

    const [metaTitle, setMetaTitle] = useState();
    const metaTitles = async () => {
      try {
        const getMetaTitle = await axios.get(`${config.BACKEND_HOST}/metaTagRouter/meta-tag?page=privacypolicy`);
      
        setMetaTitle(getMetaTitle.data.data.title);
      } catch (error) {
  
      }
    }
    useEffect(() => {
      metaTitles();
    }, [])

  return (
    <>
     <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
   
      <LayoutTwo theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="Privacy Policy" />
        <div className='main-content'>
            <div className='container'>
                <div className='pri'>
                <h1>Ultrapro Blockchain Privacy Policy</h1>
                <p>This Privacy Policy (referred to as "Policy") outlines the practices of Ultrapro Blockchain and its affiliates ("Ultrapro," "we," or "us") regarding the collection, use, and disclosure of information concerning your interaction with any Interface or Features provided by Ultrapro. By accessing and/or using our Interfaces and Features, you agree to abide by this Policy, along with our Terms of Use and any additional terms or policies explicitly incorporated into the Terms. It is important to thoroughly read and understand both this Policy and the Terms.</p>
                <p>Please note that this Policy specifically pertains to Ultrapro Blockchain and does not extend to any products, services, websites, or content offered by third parties. Ultrapro Blockchain bears no responsibility for the privacy practices of these third-party offerings. We advise reviewing the respective privacy policies provided by such third parties.</p>
                <p>Ultrapro Blockchain reserves the right to update this Policy periodically. Any changes made will be reflected in the "Last Updated" date above. We encourage our users to review this Policy often to stay updated about our privacy practices.</p>
                    <p>Modifications to this Policy become effective upon the posting of the updated version. By continuing to use Ultrapro's Interfaces or Features after the posting of any revised Policy, you signify your acceptance of the updated terms.</p>
                
                    <h2>1. Information We Collect</h2>
                    <strong>a. Information You Provide Us</strong>
                    <p>When you engage with the Site, we may gather the following details:</p>
                    <p>Contact information, including name, email address, profile picture, username, or social media details, when you opt into various programs, sign up for marketing information, submit support requests, or provide feedback.
                    Your priority regarding receiving marketing communications from us.
                    Your public wallet address when you link your wallet to access specific Features.
                    </p>
                <strong>b. Information Automatically Collected</strong> 
                <p>Certain limited information may be automatically collected when you interact with any Interface or Features, including:</p>
                <p>
                Device type, browser type, and version.<br></br>
                    Operating system and version.<br></br>
                    IP address.<br></br>
                    Publicly available blockchain data.<br></br>
                    Analytics regarding aggregate user numbers, usage patterns (e.g., page views, event counts), and aggregate location data.
                </p>
                <p>For details on our use of tracking technologies, refer to our Cookie Policy.</p>
                    <strong>2. How We Use Information</strong>
                    <p>We may utilize information about you to:</p>
                    <ul>
                        <li> Maintain, enhance, and support Interfaces and Features, and address related inquiries.</li>
                        <li> Comply with legal and regulatory requirements when necessary.</li>
                        <li>Prevent, detect, and investigate fraudulent or harmful activities.</li>
                        <li>Personalize your experience with Interfaces or Features.</li>
                        <li> Facilitate events and provide event-related information.</li>
                        <li> Provide support, resolve issues, and respond to your inquiries and feedback.</li>
                        <li>Personalize your experience with Interfaces or Features.</li>
                        <li>  Take actions you have requested and consented to.</li>
                    
                    </ul>
                    <p>You acknowledge that we may use non-identifying information (e.g., aggregated or deidentified data) for any lawful purpose.</p>
                    <strong>3. How We Share Information</strong>
                    <p>We may disclose certain information to third parties, including service providers, advisors, affiliates, and others, per this Policy. This sharing may occur when:</p>
                    <ul>
                        <li>  Engaging entities providing services like marketing, analytics, or email delivery.</li>
                        <li>  Undertaking business transactions such as mergers or acquisitions.</li>
                        <li>Complying with legal obligations or enforcing agreements.</li>
                        <li> Protecting the rights, property, or security of Ultrapro Blockchain, its employees, or users.</li>
                        <li> With your permission, share your information with entities or individuals of your choosing, subject to their privacy policies.</li>
                    </ul>
                    <strong>4. Location of Information</strong>
                    <p>Please note that information gathered through the Interfaces or Features may be transferred to, processed, stored, and utilized in the Cayman Islands and other jurisdictions. Data protection laws in these areas, including the Cayman Islands, may differ from those in your country of residence. By using the Interfaces or Features or providing any information, you consent to the transfer to and from, processing, usage, sharing, and storage of information about you in the Cayman Islands and other jurisdictions as outlined in this Policy.</p>
                    <strong>5. Access, Deletion, and Choice</strong>
                    <p>Should you require updates or deletion of specific information about you or your interactions with the Interfaces or Features, kindly reach out to us for assistance at privacy@ultraproblockchain.com.</p>
                    <p>You may have options regarding the collection and utilization of information about you. While you can opt not to provide certain information, this may limit your ability to utilize the Interfaces or Features.</p>
                    <p>Communication Preferences: If you prefer not to receive communications from us related to Ultrapro Blockchain, you can unsubscribe or adjust your communication preferences accordingly.</p>
                    <p>Browser and Device Settings: Most browsers and devices offer options to manage cookies, including preventing new cookies, receiving notifications about new cookies, or disabling cookies altogether. For further details, please refer to our Cookie Policy.</p>
                    <p>For additional details regarding specific jurisdictions, please see Section 9 below</p>
                    <strong>6. Children’s Information</strong>
                    <p>The Interfaces and Features cater to general users aged 18 years and above and are not intended for children. We do not knowingly collect personal information from children. If you believe such information has been mistakenly collected, please email privacy@ultraproblockchain.com to inform us.</p>
                    <strong>7. Retention of Information</strong>
                    <p>We retain information collected in compliance with this Policy only for as long as necessary to fulfill the relevant purposes outlined herein unless a longer retention period is mandated or permitted by law (e.g., for tax, accounting, fraud prevention, investigative, or dispute resolution purposes, or to establish or defend a claim). In certain cases, we may anonymize personal information so that it can no longer identify you, allowing us to utilize such information indefinitely without further notice.</p>
                    <strong>8. Notices</strong>
                    
                    <p><strong className='d-block mb-2'>If you have any inquiries regarding this Privacy Policy, please feel free to contact us at:</strong> 

                    Ultrapro Blockchain<br></br>
                    Legal Department<br></br>
                    IFZA Dubai Silicon Oasis, Dubai, United Arab Emirates</p>
                    <strong>Conclusion:</strong>
                    <p>At Ultrapro Blockchain, we prioritize the privacy and security of our users. This Privacy Policy outlines our commitment to transparently handle your information and ensure compliance with applicable data protection laws.</p>
                    <p>We understand that your trust is paramount, and we are dedicated to safeguarding your personal information while providing you with a seamless and personalized experience on our platform.</p>
                    <p>If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please do not hesitate to reach out to us at [contact email or address].</p>
                    <p>Thank you for choosing Ultrapro Blockchain as your trusted partner in navigating the decentralized world of limitless possibilities</p>

                </div>
            </div>  
        </div>
        </LayoutTwo>
        </>

  )
}

export default Privacypolicy