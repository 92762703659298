import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";

import HomeFour from "./pages/HomeFour";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import Service from "./pages/Service";
import NotFound from "./pages/NotFound";
import Career from "./pages/career";
import "./assets/scss/style.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Privacypolicy from "./pages/Privacypolicy";
import { Toaster } from 'react-hot-toast';
import Expo from "./pages/Expo";
import OnlineExpo from "./components/header/OnlineExpo";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UltraproTrust from "./pages/UltraproTrust";
import AcademyContent from "./pages/AcademyContent";




function setMetaTags(metaData) {
  if (metaData) {

    document.title = metaData.title || 'Default Title';

    const descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) descriptionMeta.setAttribute('content', metaData.description || 'Default Description');

    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    if (keywordsMeta) keywordsMeta.setAttribute('content', metaData.keywords || 'Default Keywords');

    const ogUrlMeta = document.querySelector('meta[property="og:url"]');
    if (ogUrlMeta) ogUrlMeta.setAttribute('content', metaData.ogUrl || 'Default OG URL');

    const ogTypeMeta = document.querySelector('meta[property="og:type"]');
    if (ogTypeMeta) ogTypeMeta.setAttribute('content', metaData.ogType || 'Default OG Type');

    const ogTitleMeta = document.querySelector('meta[property="og:title"]');
    if (ogTitleMeta) ogTitleMeta.setAttribute('content', metaData.ogTitle || 'Default OG Title');

    const ogDescriptionMeta = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionMeta) ogDescriptionMeta.setAttribute('content', metaData.ogDescription || 'Default OG Description');

    const ogImageMeta = document.querySelector('meta[property="og:image"]');
    if (ogImageMeta) ogImageMeta.setAttribute('content', metaData.ogImage || 'Default OG Image');

    const ogImageAltMeta = document.querySelector('meta[property="og:image:alt"]');
    if (ogImageAltMeta) ogImageAltMeta.setAttribute('content', metaData.ogImageAlt || 'Default OG Image Alt');

    const twitterCardMeta = document.querySelector('meta[name="twitter:card"]');
    if (twitterCardMeta) twitterCardMeta.setAttribute('content', metaData.twitterCard || 'Default Twitter Card');

    const twitterDomainMeta = document.querySelector('meta[property="twitter:domain"]');
    if (twitterDomainMeta) twitterDomainMeta.setAttribute('content', metaData.twitterDomain || 'Default Twitter Domain');

    const twitterUrlMeta = document.querySelector('meta[property="twitter:url"]');
    if (twitterUrlMeta) twitterUrlMeta.setAttribute('content', metaData.twitterUrl || 'Default Twitter URL');

    const twitterTitleMeta = document.querySelector('meta[name="twitter:title"]');
    if (twitterTitleMeta) twitterTitleMeta.setAttribute('content', metaData.twitterTitle || 'Default Twitter Title');

    const twitterDescriptionMeta = document.querySelector('meta[name="twitter:description"]');
    if (twitterDescriptionMeta) twitterDescriptionMeta.setAttribute('content', metaData.twitterDescription || 'Default Twitter Description');

    const twitterImageMeta = document.querySelector('meta[name="twitter:image"]');
    if (twitterImageMeta) twitterImageMeta.setAttribute('content', metaData.twitterImage || 'Default Twitter Image');

    const twitterImageAltMeta = document.querySelector('meta[name="twitter:image:alt"]');
    if (twitterImageAltMeta) twitterImageAltMeta.setAttribute('content', metaData.twitterImageAlt || 'Default Twitter Image Alt');

    const twitterSiteMeta = document.querySelector('meta[name="twitter:site"]');
    if (twitterSiteMeta) twitterSiteMeta.setAttribute('content', metaData.twitterSite || 'Default Twitter Site');

    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) canonicalLink.setAttribute('href', metaData.canonicalUrl || 'Default Canonical URL');
  }
}

function App() {

  useEffect(() => {
    if (window.__META_DATA__) {
      setMetaTags(window.__META_DATA__);
    }
  }, []);



  return (
    <HelmetProvider>
      <Toaster toastOptions={{ duration: 4000 }} />
      <Router>
        <ScrollToTop>
          <Switch>
          
              <Route exact path={`/`} component={HomeFour} />
              <Route path={`/about-us`} component={About} />
              <Route path={`/blog/:id/:title`} component={BlogPost} />
              <Route path={`/blog`} component={Blog} />
              <Route path={`/careers`} component={Career} />
              <Route path={`/enhanced-enterprises`} component={Service} />
              <Route path={`/privacy-policy`} component={Privacypolicy} />
              <Route path={`/live-online-expo`} component={Expo} />
              <Route path={`/online-expo`} component={OnlineExpo} />
              <Route path={`/ultrapro-trustpage`} component={UltraproTrust} />
              <Route path={`/academy-content`} component={AcademyContent} />
              <Route path={`/sitemap.xml`} />
              <Route path={`/not-found`} component={NotFound} />
              <Redirect to={`/`} />
        
           
            <ScrollToTop>

            </ScrollToTop>

          </Switch>
        </ScrollToTop>
      </Router>
    </HelmetProvider>
  );
}

export default App;
