import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import AboutContent from "../components/about-contents/AboutContent";
import AboutContentTwo from "../components/about-contents/AboutContentTwo";
import VideoPopup from "../components/video-popups/VideoPopup";
import { Helmet } from "react-helmet";


const About = () => {

  return (

    <Fragment>
    
      <LayoutTwo theme="white">
       
        <Breadcrumb title="About Us" />
       
        <AboutContent />
     
        <VideoPopup />
       
        <AboutContentTwo />
      </LayoutTwo>
    </Fragment>
  );
};

export default About;
