import PropTypes from "prop-types";
import React from "react";

const SectionTitleFour = ({ title }) => {
  return <h2 className="section__title--4">{title}</h2>;
};

SectionTitleFour.propTypes = {
  title: PropTypes.string
};

export default SectionTitleFour;
