import React from 'react'
import * as Yup from "yup";

export const careerSheema = Yup.object().shape({
  name: Yup.string()
    .required("Name is Required")
    .matches(/^[A-Za-z\s]+$/, "Numbers and Symbols are not allowed")
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name must be at most 50 characters"),

  email: Yup.string()
    .required("Email is Required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),

  mobileNumber: Yup.string()
    .required("Mobile number is Required")
    .matches(/^[0-9]+$/, "Mobile number must contain only numbers")
    .typeError("Mobile number must be a valid number")
    .min(10, "Mobile number must be at least 10 digits")
    .max(10, "Mobile number must be at most 10 digits")
    .matches(/^[0-9]+$/, "Mobile number must contain only numbers and no spaces"),



  message: Yup.string()
    .required("Message is Required"),


});




export const EmailSend = Yup.object().shape({
  name: Yup.string()
    .required("Name is Required")
    .matches(/^[A-Za-z\s]+$/, "Numbers and Symbols are not allowed")
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name must be at most 50 characters"),

  age: Yup.number()
    .required("Age is Required")
    .typeError("Age must be a number")
    .min(18, "Age must be at least 18")
    .max(90, "Age must be at most 90"),

  email: Yup.string()
    .required("Email is Required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),

  whatsAppNumber: Yup.string()
    .required("Mobile number is Required")
    .matches(/^[0-9]+$/, "Mobile number must contain only numbers")
    .typeError("Mobile number must be a valid number")
    .min(10, "Mobile number must be at least 10 digits")
    .max(10, "Mobile number must be at most 10 digits"),

  district: Yup.string()
    .required("District is Required"),

    state: Yup.string()
    .required("District is Required"),

    country: Yup.string()
    .required("District is Required"),

    zipCode: Yup.string()
    .required("ZIP Code is Required")
    .matches(/^[0-9]+$/, "Mobile number must contain only numbers"),

  interested: Yup.string()
    .required("Interested is Required")

});




export const loginWithEmailOrPhone = Yup.object().shape({
  email: Yup.string()
  .required("Email is Required")
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),

whatsAppNumber: Yup.string()
  .required("Mobile number is Required")
  .matches(/^[0-9]+$/, "Mobile number must contain only numbers")
  .typeError("Mobile number must be a valid number")
  .min(10, "Mobile number must be at least 10 digits")
  .max(10, "Mobile number must be at most 10 digits"),
});



function YupMethod() {
  return (
    <div>Yup</div>
  )
}

export default YupMethod