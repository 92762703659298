import React, { useState, useEffect } from 'react';

const CountdownToDate = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div className='dayc'>
     
      {timeLeft.days !== undefined ? (
        <>
        <div className='counts'>
          <p>{timeLeft.days} <span>days</span></p>
          <p>{timeLeft.hours} <span>hours</span> </p>
          <p>{timeLeft.minutes} <span>minutes</span></p>
          <p>{timeLeft.seconds} <span>seconds</span></p>
        </div>
       
        </>
      ) : (
        <p>Time's up!</p>
      )}
    </div>
  );
};

export default CountdownToDate;
