import React from "react";
import SectionTitleTwo from "../../components/ui/section-titles/SectionTitleTwo";
import Slider from "react-slick";

const BlogGridTwo = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="dg__blog__area bg--white pb--140 pt--130">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* section title */}
            <SectionTitleTwo title="Key Collaborators" />
          </div>
        </div>
        <div className="slid">
        <Slider {...settings}>
         <div className="btc">
            <h3>Ultrapro Exchange:</h3>
            <p>Ultrapro Exchange offers a trusted platform for seamless cryptocurrency trading, featuring robust security measures like 2-factor authentication and end-to-end encryption. With 24/7 customer support and ideal API trading capabilities, it ensures a user-friendly experience. Start your crypto journey with Ultrapro's fast KYC process and transparent operations, empowering informed decision-making. UPRO has been listed among its offerings, providing users with access to trade this digital asset securely and conveniently.
             </p>
         </div>
         <div className="btc">
            <h3>LAToken:</h3>
            <p>LATOKEN is a leading global cryptocurrency exchange offering intuitive interfaces and high security, supporting 2903 tokens and 22872 rewards. With multilingual support and integration with TradingView, LATOKEN ensures seamless crypto trading. UPRO has been listed on LATOKEN, providing users with the ability to trade this digital asset alongside other cryptocurrencies on the platform.</p>
         </div>
         <div className="btc">
            <h3>Coinstore:</h3>
            <p>Coinstore is a versatile platform offering trading for over 50 cryptocurrencies, including UPRO, listed among various trading pairs like CHX/USDT and PRNT/USDT. UPRO has been listed on Coinstore, providing users with the opportunity to trade this digital asset alongside other cryptocurrencies available on the platform.</p>
         </div>
         <div className="btc">
            <h3>Koinpark:</h3>
            <p>Koinpark is a trusted global cryptocurrency exchange platform, serving over 2.14 million customers since 2014. With advanced security features, 24/7 multilingual support, and a user-friendly interface, Koinpark offers seamless trading of 250+ cryptocurrencies. UPRO has been listed among its offerings, ensuring users can trade this digital asset securely and conveniently.</p>
         </div>
       
         <div className="btc">
            <h3>BitForex:</h3>
            <p>Bitforex is a leading cryptocurrency exchange platform renowned for its extensive range of digital assets and competitive trading pairs. With robust security measures and a user-friendly interface, Bitforex facilitates seamless trading experiences. UPRO has been listed on Bitforex, offering traders access to this digital asset among its diverse portfolio of cryptocurrencies. </p>
         </div>
         </Slider>
         </div>
      </div>
    </div>
  );
};

export default BlogGridTwo;
