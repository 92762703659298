import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";

const AboutContentTwo = () => {
  return (
    <div className="dg__service__area bg--white padding--hor">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="service__chart">
              <div className="thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/service.png"}
                  alt="Join us" draggable="false"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 offset-xl-1 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
            <div className="service__inner">
              {/* section title */}
              <SectionTitleFour title="Join Us" />
              <p>
              Join us on our journey to revolutionize the internet through blockchain technology. Whether you are a developer, entrepreneur, or enthusiast, UltraPro Blockchain offers endless possibilities for innovation and growth.
                Explore our ecosystem and be a part of the future of decentralization.

              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContentTwo;
