import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import BlogContent from "../containers/blog/BlogContent";
import { Helmet } from "react-helmet";

const Blog = () => {



  return (
    <Fragment>
     
      <LayoutTwo theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="Blog" />
        {/* blog content */}
        <BlogContent />
      </LayoutTwo>
    </Fragment>
  );
};

export default Blog;
