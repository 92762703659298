import React, { useState } from "react";
import ModalVideo from "react-modal-video";

const VideoPopup = () => {
  const [modalStatus, isOpen] = useState(false);
  return (
    <div
      className="dg__video__area"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg/8.jpg"})`
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dg__video__inner">
              <h2>Community and Governance</h2>
              <p>
              UltraPro Blockchain is driven by its community. Our decentralized autonomous organization (DAO) ensures that the governance of the network is in the hands of our users. This approach promotes transparency, inclusivity, and a truly decentralized ecosystem.

              </p>
              <div className="play__video">
                <ModalVideo
                  channel="youtube"
                  isOpen={modalStatus}
                  videoId="2X9eJF1nLiY"
                  onClose={() => isOpen(false)}
                />
                <button onClick={() => isOpen(true)} className="play__btn">
                  <img
                    src={process.env.PUBLIC_URL + "/images/about/play.png"}
                    alt="play icon" draggable="false"
                  />
                  Play Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
