import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import BlogPostContent from "../containers/blog/BlogPostContent";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const BlogPost = () => {
 
  return (
    <Fragment>
      
      <LayoutTwo theme="white">
        <Breadcrumb title="BLOG DETAILS" />
        <BlogPostContent />
      </LayoutTwo>
    </Fragment>
  );
};

export default BlogPost;
