import PropTypes from "prop-types";
import React from "react";

const BrandLogoSingle = ({ data, sliderClass }) => {
  return (
    <li
      className={`text-center ${sliderClass && sliderClass ? sliderClass : ""}`}
    >
      <a
        
        
        rel="noopener noreferrer" className="brcim" href="javascript:void(0);"
      >
        <img src={process.env.PUBLIC_URL + data.image} alt={data.alt} draggable="false"/>
        <span><img src={process.env.PUBLIC_URL + data.imageb} alt={data.alt} draggable="false"/></span>
      </a>
    </li>
  );
};

BrandLogoSingle.propTypes = {
  data: PropTypes.object,
  sliderClass: PropTypes.string
}

export default BrandLogoSingle;
