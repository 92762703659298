import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import LayoutTwo from "../layouts/LayoutTwo";
import BlogGridTwo from "../containers/blog-grids/BlogGridTwo";
import SoftwareDownloadTwo from "../components/software-downloads/SoftwareDownloadTwo";
import CounterUp from "../containers/counter-ups/CounterUp";
import WorkProcess from "../containers/work-processes/WorkProcess";
import LiveChartTwo from "../components/live-chart/LiveChartTwo";
import HowWorks from "../components/how-works/HowWorks";
import CurrencyCalculationTwo from "../components/currency-calculations/CurrencyCalculationTwo";
import HeroSliderFour from "../containers/hero-sliders/HeroSliderFour";
import axios from "axios";
const HomeFour = () => {

  return (
    <Fragment>
     

      <LayoutTwo theme="white">
        <HeroSliderFour />
        <HowWorks />
        <LiveChartTwo />
        <WorkProcess />
        <CounterUp backgroundImage="/images/bg/4.jpg" />
        <CurrencyCalculationTwo />
        <SoftwareDownloadTwo />
        <BlogGridTwo />
      </LayoutTwo>
    </Fragment>
  );
};

export default HomeFour;
