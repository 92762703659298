import React from 'react'
import SectionTitleTwo from '../../components/ui/section-titles/SectionTitleTwo'
import workprocessData from "../../data/work-processes/work-process-one.json"
import WorkProcessSingle from '../../components/work-processes/WorkProcessSingle'


const WorkProcess = () => {
  return (
    <>
    <div className="dg__working__process pb--60 pt--60 bg--white">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* section title */}
            <SectionTitleTwo title="Major advantages of leveraging
 Ultrapro blockchain" />
          </div>
        </div>
        <div className="row mt--10">
          {
            workprocessData && workprocessData.map((single, key) => {
              return (
                <WorkProcessSingle data={single} key={key} />
              )
            })
          }
        </div>
      </div>
    </div>
    <div className='faq'>
       <div className='container'>
        <div className='faq_head'>
          <h2>Frequently Asked Questions (FAQs)</h2>
          <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      What is Ultrapro blockchain?
      </button>
    </h3>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p> Ultrapro Blockchain is a high-performance blockchain platform developed by Ultrapro. It provides a secure and efficient infrastructure for various blockchain-based applications and services.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Who is the Founder of Ultrapro Blockchain?
      </button>
    </h3>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>The founder of Ultrapro Blockchain is Nagarajan Narayanasamy. He has been instrumental in developing and leading the blockchain platform, which focuses on providing secure, scalable, and efficient solutions for digital transactions.​</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Can I create my cryptocurrency on Ultrapro Blockchain?
      </button>
    </h3>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       <p>Yes, Ultrapro offers tools and resources for creating custom tokens and digital assets. This enables businesses and individuals to issue their tokens with specific use cases, such as loyalty points or fundraising.
       </p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingEight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      What is the Ultrapro Wallet and how does it work? 
      </button>
    </h3>
    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       <p>The Ultrapro Wallet is a secure digital wallet for storing and managing cryptocurrencies. It allows users to easily store, send, receive, and manage digital assets.
       </p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      What consensus mechanism does Ultrapro Blockchain use?
      </button>
    </h3>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       <p> Yes, Ultrapro does have transaction fees associated with sending tokens and executing smart contracts. These fees help prevent spam and maintain the network's performance.is enables businesses and individuals to issue their tokens with specific use cases, such as loyalty points or fundraising.
       </p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      What are the Ultrapro Blockchain usage fees?
      </button>
    </h3>
    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       <p> Yes, Ultrapro does have transaction fees associated with sending tokens and executing smart contracts. These fees help prevent spam and maintain the network's performance.
       </p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      Is there any Link between Upro coin & UltraPro Blockchain?
      </button>
    </h3>
    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       <p> Yes, UPRO Coin is the native asset (coin) of Ultrapro Blockchain. UPRO can be used for various purposes within the Ultrapro ecosystem, including paying transaction fees and participating in Staking.
       </p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      Is the security of the UltraPro Blockchain effectively assured?
      </button>
    </h3>
    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
      <div class="accordion-body"><p>  Yes, Ultrapro implements various security measures to ensure the safety of user funds and data. These include robust consensus mechanisms, regular security audits, and ongoing network activity monitoring.


       </p>
      </div>
    </div>
  </div>
        </div>
          {/* <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header><h3>What is Ultrapro blockchain?</h3></Accordion.Header>
            <Accordion.Body>
            Ultrapro Blockchain is a high-performance blockchain platform developed by Ultrapro. It provides a secure and efficient infrastructure for various blockchain-based applications and services.

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Who is the Founder of Ultrapro Blockchain?
            </Accordion.Header>
            <Accordion.Body>
            The founder of Ultrapro Blockchain is Nagarajan Narayanasamy. He has been instrumental in developing and leading the blockchain platform, which focuses on providing secure, scalable, and efficient solutions for digital transactions​

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Can I create my cryptocurrency on Ultrapro Blockchain?</Accordion.Header>
            <Accordion.Body>
            Yes, Ultrapro offers tools and resources for creating custom tokens and digital assets. This enables businesses and individuals to issue their tokens with specific use cases, such as loyalty points or fundraising.

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>What consensus mechanism does Ultrapro Blockchain use?</Accordion.Header>
            <Accordion.Body>
            Ultrapro primarily uses a Proof of Authority (PoA) consensus mechanism.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>What are the Ultrapro Blockchain usage fees?</Accordion.Header>
            <Accordion.Body>
            Yes, Ultrapro does have transaction fees associated with sending tokens and executing smart contracts. These fees help prevent spam and maintain the network's performance.

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Is there any Link between Upro coin & UltraPro Blockchain?</Accordion.Header>
            <Accordion.Body>
            Yes, UPRO Coin is the native asset (coin) of Ultrapro Blockchain. UPRO can be used for various purposes within the Ultrapro ecosystem, including paying transaction fees and participating in Staking.

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>Is the security of the UltraPro Blockchain effectively assured?</Accordion.Header>
            <Accordion.Body>
            Yes, Ultrapro implements various security measures to ensure the safety of user funds and data. These include robust consensus mechanisms, regular security audits, and ongoing network activity monitoring.


            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>What is the Ultrapro Wallet and how does it work? </Accordion.Header>
            <Accordion.Body>
            The Ultrapro Wallet is a secure digital wallet for storing and managing cryptocurrencies. It allows users to easily store, send, receive, and manage digital assets.

            </Accordion.Body>
          </Accordion.Item>
           </Accordion> */}
    </div>
       </div>
    </div>
    </>
  )
}

export default WorkProcess
